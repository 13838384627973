/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../../../common/constants';
import apiV1 from '../../../../services/axiosConfig/apiV1';

const initialState = {
    getAuditLogStatus: FETCH_STATUS.INIT,
    getAuditLogResponseData: {},
    auditLogCSVResponse: {},
    auditLogCSVStatus: FETCH_STATUS.INIT,
};

export const getAuditLog = createAsyncThunk(
    'auditLogPage/getAuditLog',
    async ({ payload }, { rejectWithValue }) => {
        try {
            const url = `${process.env.REACT_APP_STC_API_HOST}/bfg/auditlog?limit=${payload.limit}&offset=${payload.offset}&`;

            const filterParams = new URLSearchParams();

            // ip filter
            if (payload.ip) {
                filterParams.append('where[ip]', payload.ip);
            }

            // method filter
            if (payload.method) {
                filterParams.append('where[method]', payload.method);
            }

            // email filter
            if (payload.email) {
                filterParams.append('where[email]', payload.email);
            }

            // sorting
            if (payload.orderBy && payload.sortBy) {
                filterParams.append(
                    `order[${payload.sortBy}]`,
                    payload.orderBy,
                );
            }

            // dates filtering
            if (payload.from && payload.to) {
                filterParams.append('where[from]', payload.from);
                filterParams.append('where[to]', payload.to);
            }

            const response = await apiV1.sendRequest(
                `${url}${filterParams}`,
                'get',
            );

            return response.data.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getLogsCSV = createAsyncThunk(
    'auditLogPage/getLogsCSV',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(
                `${process.env.REACT_APP_STC_API_HOST}/bfg/auditlog/csv`,
            );

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

const auditLogSlice = createSlice({
    name: 'auditLogSlice',
    initialState,
    reducers: {
        changeLimit(state, action) {
            return {
                ...state,
                getAuditLogResponseData: {
                    ...state.getAuditLogResponseData,
                    limit: action.payload.limit,
                    offset: 0,
                },
            };
        },
        setOffset(state, action) {
            return {
                ...state,
                getAuditLogResponseData: {
                    ...state.getAuditLogResponseData,
                    offset: action.payload.offset,
                },
            };
        },
        changePage(state, action) {
            return {
                ...state,
                getAuditLogResponseData: {
                    ...state.getAuditLogResponseData,
                    offset: action.payload.offset,
                },
            };
        },
        resetCSVStatus(state) {
            return {
                ...state,
                auditLogCSVStatus: FETCH_STATUS.INIT,
            };
        },
    },
    extraReducers: {
        [getAuditLog.pending]: (state) => {
            state.getAuditLogStatus = FETCH_STATUS.FETCHING;
        },
        [getAuditLog.fulfilled]: (state, action) => {
            state.getAuditLogStatus = FETCH_STATUS.SUCCESS;
            state.getAuditLogResponseData = action?.payload;
        },
        [getAuditLog.rejected]: (state, action) => {
            state.getAuditLogStatus = FETCH_STATUS.ERROR;
            state.getAuditLogResponseData = action?.payload;
        },
        [getLogsCSV.pending]: (state) => {
            state.auditLogCSVStatus = FETCH_STATUS.FETCHING;
        },
        [getLogsCSV.fulfilled]: (state, action) => {
            state.auditLogCSVStatus = FETCH_STATUS.SUCCESS;
            state.auditLogCSVResponse = action?.payload;
        },
        [getLogsCSV.rejected]: (state, action) => {
            state.auditLogCSVStatus = FETCH_STATUS.ERROR;
            state.auditLogCSVResponse = action?.payload;
        },
    },
});

export const { changeLimit, changePage, setOffset, resetCSVStatus } =
    auditLogSlice.actions;

export default auditLogSlice.reducer;
