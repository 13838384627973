import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDocumentTitle } from '../../utils/useDocumentTitle';

const mainContentStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    height: '100%',
    paddingBottom: '1rem',
};

const MainLayout = ({
    title,
    children,
    titleComponent: TitleComponent,
    titleComponentProps,
    backTo,
}) => {
    const history = useHistory();

    useDocumentTitle('STAC - User roles');

    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                marginTop: '50px',
                width: '100%',
            }}
        >
            {backTo && (
                <Box sx={{ position: 'absolute', mt: '2rem' }}>
                    <Button
                        variant="text"
                        onClick={() => history.replace(backTo)}
                    >
                        Back to roles
                    </Button>
                </Box>
            )}
            {(title || TitleComponent) && (
                <Box sx={{ pb: '50px' }}>
                    {title ? (
                        <Typography variant="h1">{title}</Typography>
                    ) : (
                        <TitleComponent {...titleComponentProps} />
                    )}
                </Box>
            )}

            <Box sx={mainContentStyles}>{children}</Box>
        </Container>
    );
};

export default MainLayout;
