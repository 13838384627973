import React from 'react';

import UserRolesGrid from './components/userRolesGrid/UserRolesGrid';
import AddNewRoleForm from './components/addNewRoleForm/AddNewRoleForm';
import { MainLayout } from '../../../../components';

const UserRoles = () => {
    return (
        <MainLayout title="User roles">
            <AddNewRoleForm />

            <UserRolesGrid />
        </MainLayout>
    );
};

export default UserRoles;
