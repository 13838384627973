import { useEffect } from 'react';

export const useDocumentTitle = (title) => {
    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(
        () => () => {
            document.title = 'Secure Trial Admin Console';
        },
        [],
    );
};
