/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    severity: 'info',
    message: '',
};

const alertSlice = createSlice({
    name: 'alertSlice',
    initialState,
    reducers: {
        updateAlertParams: (state, action) => {
            return { ...action.payload, isOpen: true };
        },
        closeAlert: (state) => {
            state.isOpen = false;
        },
    },
});

export const { updateAlertParams, closeAlert } = alertSlice.actions;

export default alertSlice.reducer;
