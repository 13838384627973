/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../../../../../common/constants';
import apiV1 from '../../../../../../services/axiosConfig/apiV1';
import { updateAlertParams } from '../../../../../../slices/alert/alertSlice';
import { errorHandler } from '../../../../../../common/utils';

const initialState = {
    extendTrialStatus: FETCH_STATUS.INIT,
    extendTrialResult: {},
    getTrialDetailsStatus: FETCH_STATUS.INIT,
    getTrialDetailsResult: {},
    getTrialsStatus: FETCH_STATUS.INIT,
    getTrialsResult: {},
    getPackagesStatus: FETCH_STATUS.INIT,
    getPackagesResult: {},
    editTrialPackageStatus: FETCH_STATUS.INIT,
    editTrialPackageResult: {},
    subscriptionDetailsState: FETCH_STATUS.INIT,
    subscriptionDetails: {},
    changeTrialStatusState: FETCH_STATUS.INIT,
    changeTrialStatusResult: {},
};

export const editTrial = createAsyncThunk(
    'trial/edit',
    async (
        { payload, parentOrgId, trialId, isDelete },
        { rejectWithValue, dispatch },
    ) => {
        try {
            // add seats and adminemails in the payload
            return await apiV1.sendRequest(
                `/bfg/organizations/${parentOrgId}/trials/${trialId}`,
                isDelete ? 'delete' : 'put',
                payload,
            );
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const changeTrialStatus = createAsyncThunk(
    'trial/changeTrialStatus',
    async ({ status, parentOrgId, trialId }, { rejectWithValue, dispatch }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${parentOrgId}/trials/${trialId}/${status}`,
                'put',
            );
        } catch (err) {
            dispatch(
                updateAlertParams({ message: err?.error, severity: 'error' }),
            );
            return rejectWithValue(err);
        }
    },
);

export const getPackages = createAsyncThunk(
    'organizations/getPackages',
    async ({ productType }, { rejectWithValue }) => {
        let url = `/bfg/packages`;

        if (productType) {
            url += `?productType=${productType}`;
        }

        try {
            const response = await apiV1.sendRequest(url, 'get');
            const trialPackages = response.data.data.map((trialPackage) => {
                return {
                    ...trialPackage,
                    label: trialPackage.name,
                    value: trialPackage.externalId,
                };
            });

            response.data.data = trialPackages;
            return response;
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getTrials = createAsyncThunk(
    'organizations/getCustomers',
    async ({ orgId, page, limit, search }, { rejectWithValue }) => {
        const requestString = new URLSearchParams();

        requestString.append('page', page);
        requestString.append('limit', limit);

        if (search) {
            requestString.append('search', search);
        }

        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${orgId}/customers?${requestString}`,
                'get',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const extendTrial = createAsyncThunk(
    'trials/extendTrial',
    async ({ parentOrgId, trialId }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${parentOrgId}/trials/${trialId}/trialextensions`,
                'post',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const updateEndDate = createAsyncThunk(
    'trials/updateEndDate',
    async ({ parentOrgId, trialId, payload }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${parentOrgId}/trials/${trialId}/updateEndDate`,
                'put',
                payload,
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getCustomerDetails = createAsyncThunk(
    'trials/getCustomerDetails',
    async ({ parentOrgId, customerId }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${parentOrgId}/customers/byCustomer/${customerId}`,
                'get',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getSubscriptionDetails = createAsyncThunk(
    'trials/getSubscriptionDetails',
    async ({ parentOrgId, trialId }, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(
                `bfg/organizations/${parentOrgId}/trials/${trialId}/details`,
            );
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

const trialsSlice = createSlice({
    name: 'trialsSlice',
    initialState,
    reducers: {
        clearTrialExtensionData(state) {
            return {
                ...state,
                extendTrialStatus: FETCH_STATUS.INIT,
                extendTrialResult: {},
            };
        },
        clearGetTrialsDetailsData(state) {
            return {
                ...state,
                getTrialDetailsStatus: FETCH_STATUS.INIT,
                getTrialDetailsResult: {},
            };
        },
        clearTrialDeleteData(state) {
            return {
                ...state,
                editTrialPackageResult: {},
                editTrialPackageStatus: FETCH_STATUS.INIT,
            };
        },
    },
    extraReducers: {
        // extend trial
        [extendTrial.pending]: (state) => {
            state.extendTrialStatus = FETCH_STATUS.FETCHING;
        },
        [extendTrial.fulfilled]: (state, action) => {
            state.extendTrialStatus = FETCH_STATUS.SUCCESS;
            state.extendTrialResult = action.payload;
        },
        [extendTrial.rejected]: (state, action) => {
            state.extendTrialStatus = FETCH_STATUS.ERROR;
            state.extendTrialResult = action.payload;
        },

        // get trial details
        [getCustomerDetails.pending]: (state) => {
            state.getTrialDetailsStatus = FETCH_STATUS.FETCHING;
        },
        [getCustomerDetails.fulfilled]: (state, action) => {
            state.getTrialDetailsStatus = FETCH_STATUS.SUCCESS;
            state.getTrialDetailsResult = action.payload;
        },
        [getCustomerDetails.rejected]: (state, action) => {
            state.getTrialDetailsStatus = FETCH_STATUS.ERROR;
            state.getTrialDetailsResult = action.payload;
        },

        // get trials
        [getTrials.pending]: (state) => {
            state.getTrialsStatus = FETCH_STATUS.FETCHING;
        },
        [getTrials.fulfilled]: (state, action) => {
            state.getTrialsStatus = FETCH_STATUS.SUCCESS;
            state.getTrialsResult = action.payload;
        },
        [getTrials.rejected]: (state, action) => {
            state.getTrialsStatus = FETCH_STATUS.ERROR;
            state.getTrialsResult = action.payload;
        },

        // edit trials
        [editTrial.pending]: (state) => {
            state.editTrialPackageStatus = FETCH_STATUS.FETCHING;
        },
        [editTrial.fulfilled]: (state, action) => {
            state.editTrialPackageStatus = FETCH_STATUS.SUCCESS;
            state.editTrialPackageResult = action.payload;
        },
        [editTrial.rejected]: (state, action) => {
            state.editTrialPackageStatus = FETCH_STATUS.ERROR;
            state.editTrialPackageResult = action.payload;
        },

        // get packages
        [getPackages.pending]: (state) => {
            state.getPackagesStatus = FETCH_STATUS.FETCHING;
        },
        [getPackages.fulfilled]: (state, action) => {
            state.getPackagesStatus = FETCH_STATUS.SUCCESS;
            state.getPackagesResult = action.payload;
        },
        [getPackages.rejected]: (state, action) => {
            state.getPackagesStatus = FETCH_STATUS.ERROR;
            state.getPackagesResult = action.payload;
        },

        // get subscription details
        [getSubscriptionDetails.pending]: (state) => {
            state.subscriptionDetailsState = FETCH_STATUS.FETCHING;
        },
        [getSubscriptionDetails.fulfilled]: (state, action) => {
            state.subscriptionDetails = action?.payload;
            state.subscriptionDetailsState = FETCH_STATUS.SUCCESS;
        },
        [getSubscriptionDetails.rejected]: (state) => {
            state.subscriptionDetailsState = FETCH_STATUS.ERROR;
        },

        // change status of trial (disable/enable)
        [changeTrialStatus.pending]: (state) => {
            state.changeTrialStatusState = FETCH_STATUS.FETCHING;
        },
        [changeTrialStatus.fulfilled]: (state, action) => {
            state.changeTrialStatusState = FETCH_STATUS.SUCCESS;
            state.changeTrialStatusResult = action?.payload;
        },
        [changeTrialStatus.rejected]: (state) => {
            state.changeTrialStatusState = FETCH_STATUS.ERROR;
        },
    },
});

export const {
    clearTrialExtensionData,
    clearGetTrialsDetailsData,
    clearTrialDeleteData,
} = trialsSlice.actions;

export default trialsSlice.reducer;
