import React from 'react';
import Box from '@mui/material/Box';
import ModalComponent from '@mui/material/Modal';
import LoadingDots from '@opendns/dpl-loading-dots';
import Button from '@opendns/dpl-button';

import s from './modalContainer.module.scss';
import g from '../../styles/globalStyles.module.scss';

const ModalContainer = ({
    isOpen,
    handleClose,
    isFetching,
    submitBtnAction,
    submitBtnName,
    submitBtnVariant,
    cancelBtnAction,
    cancelBtnName,
    children,
    isSubmitDisabled,
}) => {
    return (
        <ModalComponent open={isOpen} onClose={handleClose}>
            <Box className={s.container}>
                {isFetching && (
                    <div className={g.contentPreloaderWithBlur}>
                        <LoadingDots
                            size="small"
                            data-testid="modalPreloader"
                        />
                    </div>
                )}

                {children}

                {submitBtnName && cancelBtnName && (
                    <div className={s.btnContainer}>
                        <Button
                            type="secondary"
                            onClick={cancelBtnAction}
                            data-testid="cancelModalAction"
                        >
                            {cancelBtnName}
                        </Button>

                        <Button
                            variant={submitBtnVariant}
                            data-testid="submitModalAction"
                            onClick={submitBtnAction}
                            disabled={isSubmitDisabled}
                        >
                            {submitBtnName}
                        </Button>
                    </div>
                )}
            </Box>
        </ModalComponent>
    );
};

export default ModalContainer;
