/* eslint-disable no-shadow */
import React from 'react';
import { Controller } from 'react-hook-form';
import {
    Autocomplete as AutocompleteComponent,
    TextField,
} from '@mui/material';

const AutocompleteSingle = ({ options = [], name, label, ...rest }) => {
    return (
        <Controller
            name={name}
            render={({ field: { onChange, name }, fieldState }) => {
                return (
                    <AutocompleteComponent
                        {...rest}
                        options={options}
                        onChange={(_e, item) => {
                            onChange(item?.value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                label={label}
                                name={name}
                                error={!!fieldState.error}
                                helperText={
                                    fieldState.error &&
                                    (fieldState.error.message ||
                                        fieldState.error.type)
                                }
                            />
                        )}
                    />
                );
            }}
        />
    );
};

export default AutocompleteSingle;
