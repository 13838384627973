import React, { memo } from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const TextArea = ({ name, label, ...rest }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const error = errors[name];

    const helperText = error ? error.message || error.type : '';

    return (
        <TextField
            {...register(name)}
            label={label}
            fullWidth
            minRows={3}
            maxRows={4}
            multiline
            error={!!error}
            helperText={helperText}
            {...rest}
        />
    );
};

export default memo(TextArea);
