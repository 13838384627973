import React, { memo } from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const TextInput = ({ name, label, options = {}, ...rest }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const error = errors[name];

    const helperText = !!error && (error.message || error.type);

    return (
        <TextField
            {...register(name, options)}
            label={label}
            fullWidth
            size="small"
            error={!!error}
            helperText={helperText}
            {...rest}
        />
    );
};

export default memo(TextInput);
