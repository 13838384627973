/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../../../common/constants';
import apiV1 from '../../../../services/axiosConfig/apiV1';

const initialState = {
    getOrgsStatus: FETCH_STATUS.INIT,
    getOrgDetailsStatus: FETCH_STATUS.INIT,
    getOrgUsersStatus: FETCH_STATUS.INIT,
    getOrgCustomersStatus: FETCH_STATUS.INIT,
    changeOrgNameStatus: FETCH_STATUS.INIT,
    changeOrgNameResult: {},
    getOrgsResult: {},
    offset: 0,
    limit: 10,
    paginationCurrentPage: 1,
    getOrgDetailsResult: {
        users: {},
        orgDetails: {},
        customers: {},
        trialsOffset: 0,
        trialsLimit: 5,
    },
    updateOrganizationStatus: FETCH_STATUS.INIT,
    updateOrganizationResult: {},
    getOrgsInvitesStatus: FETCH_STATUS.INIT,
    getOrgsInvitesResult: {},
    resendUserInviteStatus: FETCH_STATUS.INIT,
    resendUserInviteResult: {},
    deleteInvitedUserStatus: FETCH_STATUS.INIT,
    deleteInvitedUserResult: {},
    orgTypesStatus: FETCH_STATUS.INIT,
    orgTypes: [],
};

export const changeOrgName = createAsyncThunk(
    'organizations/changeOrgName',
    async ({ orgId, name }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${orgId}/name`,
                'post',
                { name },
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getOrgTrials = createAsyncThunk(
    'organizations/getOrganizationTrials',
    async ({ orgId, offset, limit }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${orgId}/customers?offset=${offset}&limit=${limit}`,
                'get',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getOrgUsers = createAsyncThunk(
    'organizations/getOrganizationUsers',
    async ({ orgId, email }, { rejectWithValue }) => {
        try {
            const requestString = new URLSearchParams();

            if (email) {
                requestString.append('email', email);
            }

            return await apiV1.sendRequest(
                `/bfg/organizations/${orgId}/users?${requestString}`,
                'get',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getOrgDetails = createAsyncThunk(
    'organizations/getOrganizationDetails',
    async (orgId, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${orgId}`,
                'get',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getOrgs = createAsyncThunk(
    'organizations/getOrganizations',
    async (data, { rejectWithValue }) => {
        const requestString = new URLSearchParams();

        requestString.append('offset', data.offset);
        requestString.append('limit', data.limit);

        if (data.orgName) {
            requestString.append('orgName', data.orgName);
        }

        if (data.type) {
            requestString.append('type', data.type);
        }

        try {
            return await apiV1.sendRequest(
                `/bfg/organizations?${requestString}`,
                'get',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const updateOrganization = createAsyncThunk(
    'organizations/updateOrganization',
    async ({ orgId, body }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${orgId}`,
                'put',
                body,
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getOrgsInvites = createAsyncThunk(
    'organizations/getOrgInvites',
    async ({ orgId }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${orgId}/users/invites`,
                'get',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const resendUserInvite = createAsyncThunk(
    'organizations/resendUserInvite',
    async ({ orgId, payload }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${orgId}/users/invites`,
                'post',
                payload,
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const deleteInvitedUser = createAsyncThunk(
    'organizations/deleteInvitedUser',
    async ({ orgId, invId }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${orgId}/users/invites/${invId}`,
                'delete',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getOrgTypes = createAsyncThunk(
    'organizations/getOrgTypes',
    async (_, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest('/bfg/organizations/types', 'get');
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

const organizationsSlice = createSlice({
    name: 'organizationsSlice',
    initialState,
    reducers: {
        clearGetOrgsStatus(state) {
            return {
                ...state,
                getOrgsStatus: FETCH_STATUS.INIT,
                getOrgsResult: {},
            };
        },
        nextPage(state) {
            return {
                ...state,
                paginationCurrentPage: state.paginationCurrentPage + 1,
                offset: state.offset + state.limit,
            };
        },
        previousPage(state) {
            return {
                ...state,
                paginationCurrentPage: state.paginationCurrentPage - 1,
                offset: state.offset - state.limit,
            };
        },
        changePage(state, action) {
            return {
                ...state,
                paginationCurrentPage: action.payload,
                offset: state.limit * action.payload - state.limit,
            };
        },
        changePaginationLimit(state, action) {
            return {
                ...state,
                limit: action.payload,
                offset:
                    action.payload * state.paginationCurrentPage -
                    action.payload,
            };
        },
        clearOffsetAndPage(state) {
            return {
                ...state,
                offset: 0,
                paginationCurrentPage: 1,
            };
        },
        clearOrgDetails(state) {
            return {
                ...state,
                getOrgDetailsStatus: FETCH_STATUS.INIT,
                getOrgUsersStatus: FETCH_STATUS.INIT,
                getOrgCustomersStatus: FETCH_STATUS.INIT,
                getOrgDetailsResult: {
                    ...state.getOrgDetailsResult,
                    customers: {},
                    users: {},
                },
            };
        },
        clearChangeOrgNameData(state) {
            return {
                ...state,
                changeOrgNameStatus: FETCH_STATUS.INIT,
                changeOrgNameResult: {},
            };
        },
        clearOrgUsersState(state) {
            return {
                ...state,
                getOrgUsersStatus: FETCH_STATUS.INIT,
                getOrgDetailsResult: {
                    ...state.getOrgDetailsResult,
                    users: {},
                },
            };
        },
        clearOrgCustomersState(state) {
            return {
                ...state,
                getOrgCustomersStatus: FETCH_STATUS.INIT,
                getOrgDetailsResult: {
                    ...state.getOrgDetailsResult,
                    customers: {},
                },
            };
        },
        clearRemoveInvitedUserStatus(state) {
            return {
                ...state,
                deleteInvitedUserStatus: FETCH_STATUS.INIT,
            };
        },
        resetUpdateOrganizationStatus(state) {
            return {
                ...state,
                updateOrganizationStatus: FETCH_STATUS.INIT,
            };
        },
    },
    extraReducers: {
        // organizations section
        [getOrgs.pending]: (state) => {
            state.getOrgsStatus = FETCH_STATUS.FETCHING;
        },
        [getOrgs.fulfilled]: (state, action) => {
            state.getOrgsStatus = FETCH_STATUS.SUCCESS;
            state.getOrgsResult = action?.payload;
        },
        [getOrgs.rejected]: (state, action) => {
            state.getOrgsStatus = FETCH_STATUS.ERROR;
            state.getOrgsResult = action?.payload;
        },

        // org details extra reducers
        [getOrgDetails.pending]: (state) => {
            state.getOrgDetailsStatus = FETCH_STATUS.FETCHING;
        },
        [getOrgDetails.fulfilled]: (state, action) => {
            state.getOrgDetailsStatus = FETCH_STATUS.SUCCESS;
            state.getOrgDetailsResult.orgDetails = action?.payload;
        },
        [getOrgDetails.rejected]: (state, action) => {
            state.getOrgDetailsStatus = FETCH_STATUS.ERROR;
            state.getOrgDetailsResult.orgDetails = action?.payload;
        },

        // org users
        [getOrgUsers.pending]: (state) => {
            state.getOrgUsersStatus = FETCH_STATUS.FETCHING;
        },
        [getOrgUsers.fulfilled]: (state, action) => {
            state.getOrgUsersStatus = FETCH_STATUS.SUCCESS;
            state.getOrgDetailsResult.users = action?.payload;
        },
        [getOrgUsers.rejected]: (state, action) => {
            state.getOrgUsersStatus = FETCH_STATUS.ERROR;
            state.getOrgDetailsResult.users = action?.payload;
        },

        // org customers
        [getOrgTrials.pending]: (state) => {
            state.getOrgCustomersStatus = FETCH_STATUS.FETCHING;
        },
        [getOrgTrials.fulfilled]: (state, action) => {
            state.getOrgCustomersStatus = FETCH_STATUS.SUCCESS;
            state.getOrgDetailsResult.customers = action?.payload;
        },
        [getOrgTrials.rejected]: (state, action) => {
            state.getOrgCustomersStatus = FETCH_STATUS.ERROR;
            state.getOrgDetailsResult.customers = action?.payload;
        },

        // change name
        [changeOrgName.pending]: (state) => {
            state.changeOrgNameStatus = FETCH_STATUS.FETCHING;
        },
        [changeOrgName.fulfilled]: (state, action) => {
            state.changeOrgNameStatus = FETCH_STATUS.SUCCESS;
            state.changeOrgNameResult = action?.payload;
        },
        [changeOrgName.rejected]: (state, action) => {
            state.changeOrgNameStatus = FETCH_STATUS.ERROR;
            state.changeOrgNameResult = action?.payload;
        },

        // Update Organization
        [updateOrganization.pending]: (state) => {
            state.updateOrganizationStatus = FETCH_STATUS.FETCHING;
        },
        [updateOrganization.fulfilled]: (state, action) => {
            state.updateOrganizationStatus = FETCH_STATUS.SUCCESS;
            state.updateOrganizationResult = action?.payload;
        },
        [updateOrganization.rejected]: (state, action) => {
            state.updateOrganizationStatus = FETCH_STATUS.ERROR;
            state.updateOrganizationResult = action?.payload;
        },

        // orgs invites
        [getOrgsInvites.pending]: (state) => {
            state.getOrgsInvitesStatus = FETCH_STATUS.FETCHING;
        },
        [getOrgsInvites.fulfilled]: (state, action) => {
            state.getOrgsInvitesStatus = FETCH_STATUS.SUCCESS;
            state.getOrgsInvitesResult = action?.payload;
        },
        [getOrgsInvites.rejected]: (state, action) => {
            state.getOrgsInvitesStatus = FETCH_STATUS.ERROR;
            state.getOrgsInvitesResult = action?.payload;
        },
        [resendUserInvite.pending]: (state) => {
            state.resendUserInviteStatus = FETCH_STATUS.FETCHING;
        },
        [resendUserInvite.fulfilled]: (state, action) => {
            state.resendUserInviteStatus = FETCH_STATUS.SUCCESS;
            state.resendUserInviteResult = action?.payload;
        },
        [resendUserInvite.rejected]: (state, action) => {
            state.resendUserInviteStatus = FETCH_STATUS.ERROR;
            state.resendUserInviteResult = action?.payload;
        },
        [deleteInvitedUser.pending]: (state) => {
            state.deleteInvitedUserStatus = FETCH_STATUS.FETCHING;
        },
        [deleteInvitedUser.fulfilled]: (state, action) => {
            state.deleteInvitedUserStatus = FETCH_STATUS.SUCCESS;
            state.deleteInvitedUserResult = action?.payload;
        },
        [deleteInvitedUser.rejected]: (state, action) => {
            state.deleteInvitedUserStatus = FETCH_STATUS.ERROR;
            state.deleteInvitedUserResult = action?.payload;
        },

        // orgTypes
        [getOrgTypes.pending]: (state) => {
            state.orgTypesStatus = FETCH_STATUS.FETCHING;
        },
        [getOrgTypes.fulfilled]: (state, action) => {
            state.orgTypesStatus = FETCH_STATUS.SUCCESS;
            state.orgTypes = action?.payload?.data?.data || [];
        },
        [getOrgTypes.rejected]: (state) => {
            state.orgTypesStatus = FETCH_STATUS.ERROR;
        },
    },
});
export const {
    clearGetOrgsStatus,
    nextPage,
    previousPage,
    changePage,
    changePaginationLimit,
    clearOffsetAndPage,
    clearOrgDetails,
    clearChangeOrgNameData,
    clearOrgUsersState,
    clearOrgCustomersState,
    clearRemoveInvitedUserStatus,
    resetUpdateOrganizationStatus,
} = organizationsSlice.actions;

export default organizationsSlice.reducer;
