import React from 'react';

import s from './entryWrapperFooter.scss';

const EntryWrapperFooter = () => {
    return (
        <footer className={s.loginFooter} data-testid="entryWrapperFooter">
            <p className={s.copyrightDescription}>
                Copyright © {new Date().getFullYear()} Secure Trials Management
                Console |
            </p>

            <a
                data-testid="termsOfUse"
                href="https://www.cisco.com/c/en/us/about/legal/cloud-and-software/end_user_license_agreement.html?_ga=2.254957448.1899395472.1657553155-2135873393.1657553155"
                target="_blank"
                className={s.footerLink}
                rel="noreferrer"
            >
                Terms of Use
            </a>

            <span className={s.barrier}>|</span>

            <a
                data-testid="ciscoPrivacyStatement"
                href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html"
                target="_blank"
                className={s.footerLink}
                rel="noreferrer"
            >
                Cisco Online Privacy Statement
            </a>

            <span className={s.barrier}>|</span>

            <a
                data-testid="support"
                href="mailto:sec-licensing-casegen@cisco.com?subject=STC Case :"
                target="_blank"
                className={s.footerLink}
                rel="noreferrer"
            >
                Support
            </a>
        </footer>
    );
};

export default EntryWrapperFooter;
