import React from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';

const Dropdown = ({ options, name, label, defaultValueId, ...rest }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const error = errors[name];

    const helperText = error ? error.message || error.type : '';

    return (
        <FormControl {...rest} fullWidth size="small" error={!!error}>
            <InputLabel>{label}</InputLabel>
            <Select
                {...register(name)}
                label={label}
                defaultValue={defaultValueId || ''}
                {...rest}
            >
                {options?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default Dropdown;
