import { useHistory } from 'react-router-dom';

// custom hook for checking the hex length
const useHexCheck = (params) => {
    const history = useHistory();

    const regex = /[\dA-Fa-f]{40}/g;
    const { hex } = params;

    if (!regex.test(hex)) {
        history.replace('/login');
    }

    return hex;
};

export default useHexCheck;
