import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '@opendns/dpl-text-input';
import { EmailIcon, SecureIcon, UserIcon } from '@opendns/svg-icons';
import Button from '@opendns/dpl-button';
import Error from '@opendns/dpl-error';
import validator from 'validator';
import LoadingDots from '@opendns/dpl-loading-dots';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import EntryWrapper from '../../components/entryWrapper/EntryWrapper';
import logo from '../../images/logo/loginLogo.svg';
import useHexCheck from '../../common/checkHex';
import { useDocumentTitle } from '../../utils/useDocumentTitle';
import { onboardInternalCiscoUser } from '../dashboardPage/components/onboarding/onboardSlice';
import { FETCH_STATUS, IS_PASSWORD_REQUIRED } from '../../common/constants';
import { updateAlertParams } from '../../slices/alert/alertSlice';

import g from '../../styles/globalStyles.module.scss';
import s from './onboardUserPage.module.scss';

const CreateInternalCiscoUserPage = () => {
    useDocumentTitle('STAC - Onboarding');

    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();

    const [confirmPasswordsError, setConfirmPasswordsError] = useState(false);

    // check the hex validity hook
    useHexCheck(params);

    const { onboardInternalUserStatus, onboardResult } = useSelector(
        (state) => state.onboard,
    );

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    useEffect(() => {
        if (onboardInternalUserStatus === FETCH_STATUS.SUCCESS) {
            history.replace('/login');
        }

        if (onboardInternalUserStatus === FETCH_STATUS.ERROR) {
            dispatch(
                updateAlertParams({
                    severity: 'error',
                    message: onboardResult.error,
                }),
            );
        }
    }, [onboardInternalUserStatus, onboardResult]);

    const handleCreateSTCUser = (formData) => {
        if (formData.password !== formData.confirmPassword) {
            // if passwords are not correct
            setConfirmPasswordsError(true);
        } else {
            const formDataWithUrlToken = {
                ...formData,
                token: params.hex,
            };
            dispatch(onboardInternalCiscoUser(formDataWithUrlToken));
            setConfirmPasswordsError(false);
        }
    };

    return (
        <EntryWrapper>
            <img src={logo} alt="logo" />

            <h2 data-testid="formTitle">STC Management Console - Onboarding</h2>

            <form className={s.form}>
                {onboardInternalUserStatus === FETCH_STATUS.FETCHING && (
                    <div
                        data-testid="onboardUserLoader"
                        className={g.contentPreloaderWithBlur}
                    >
                        <LoadingDots size="small" />
                    </div>
                )}

                {/* Firstname */}
                <div className={g.inputBlock}>
                    <p className={g.inputLabel}>Firstname</p>

                    <div className={g.inputWrap}>
                        <div className={g.icon}>
                            <UserIcon color="p2" size={16} />
                        </div>

                        <TextInput
                            name="onboardFirstNameInput"
                            data-testid="onboardFirstNameInput"
                            {...register('firstName', { required: true })}
                        />
                    </div>

                    {errors.firstName && (
                        <div
                            className={g.errorMessage}
                            data-testid="onboardRequiredFirsnameError"
                        >
                            <Error>Firstname is required!</Error>
                        </div>
                    )}
                </div>

                {/* Lastname */}
                <div className={g.inputBlock}>
                    <p className={g.inputLabel}>Lastname</p>

                    <div className={g.inputWrap}>
                        <div className={g.icon}>
                            <UserIcon color="p2" size={16} />
                        </div>

                        <TextInput
                            name="onboardLastNameInput"
                            data-testid="onboardLastNameInput"
                            {...register('lastName', { required: true })}
                        />
                    </div>

                    {errors.lastName && (
                        <div
                            className={g.errorMessage}
                            data-testid="onboardRequiredLastnameError"
                        >
                            <Error>Lastname is required!</Error>
                        </div>
                    )}
                </div>

                {/* Email */}
                <div className={g.inputBlock}>
                    <p className={g.inputLabel}>Email</p>

                    <div className={g.inputWrap}>
                        <div className={g.icon}>
                            <EmailIcon color="p2" size={16} />
                        </div>

                        <TextInput
                            isError={Boolean(errors?.email?.type)}
                            name="onboardEmailInput"
                            data-testid="onboardEmailInput"
                            {...register('email', {
                                required: true,
                                validate: (email) => validator.isEmail(email),
                            })}
                        />
                    </div>

                    {errors?.email?.type === 'required' && (
                        <div
                            className={s.errorMessage}
                            data-testid="onboardRequiredEmailError"
                        >
                            <Error>Email is required!</Error>
                        </div>
                    )}

                    {errors?.email?.type === 'validate' && (
                        <div
                            className={s.errorMessage}
                            data-testid="onboardInvalidEmail"
                        >
                            <Error>Email is not valid!</Error>
                        </div>
                    )}
                </div>

                {IS_PASSWORD_REQUIRED && (
                    <>
                        {/* Password */}
                        <div className={g.inputBlock}>
                            <p className={g.inputLabel}>Password</p>

                            <div className={g.inputWrap}>
                                <div className={g.icon}>
                                    <SecureIcon color="p2" size={16} />
                                </div>

                                <TextInput
                                    name="onboardPasswordInput"
                                    data-testid="onboardPasswordInput"
                                    type="password"
                                    {...register('password', {
                                        required: true,
                                        minLength: 8,
                                    })}
                                />
                            </div>

                            {errors.password?.type === 'required' && (
                                <div
                                    className={g.errorMessage}
                                    data-testid="onboardRequiredPasswordError"
                                >
                                    <Error>Password is required!</Error>
                                </div>
                            )}

                            {errors.password?.type === 'minLength' && (
                                <div
                                    className={g.errorMessage}
                                    data-testid="onboardMinLengthPasswordError"
                                >
                                    <Error>
                                        Password must be at least 8 characters!
                                    </Error>
                                </div>
                            )}
                        </div>

                        {/* Confirm Password */}
                        <div className={g.inputBlock}>
                            <p className={g.inputLabel}>Confirm password</p>

                            <div className={g.inputWrap}>
                                <div className={g.icon}>
                                    <SecureIcon color="p2" size={16} />
                                </div>

                                <TextInput
                                    name="onboardConfirmPasswordInput"
                                    data-testid="onboardConfirmPasswordInput"
                                    type="password"
                                    {...register('confirmPassword', {
                                        required: true,
                                    })}
                                />
                            </div>

                            {errors.confirmPassword?.type === 'required' && (
                                <div
                                    className={g.errorMessage}
                                    data-testid="onboardRequiredConfirmPasswordError"
                                >
                                    <Error>
                                        Password confirmation is required!
                                    </Error>
                                </div>
                            )}

                            {confirmPasswordsError && (
                                <div
                                    className={g.errorMessage}
                                    data-testid="onboardMathConfirmPasswordError"
                                >
                                    <Error>{`Passwords don't match!`}</Error>
                                </div>
                            )}
                        </div>

                        <div className={s.onboardingPasswordInfo}>
                            <h3>Passwords must:</h3>

                            <ul>
                                <li>Be between 8 and 256 characters long</li>

                                <li>
                                    Contain at least one uppercase and one
                                    lowercase letter
                                </li>

                                <li>
                                    Contain at least one digit and one special
                                    character (for example: *, $, or &)
                                </li>

                                <li>Not contain part of your username</li>
                            </ul>
                        </div>
                    </>
                )}

                <div className={s.actionButtonsContainer}>
                    <div
                        className={s.buttonsSpaceBetween}
                        data-testid="onboardNonSTCUserBtn"
                    >
                        <Button onClick={handleSubmit(handleCreateSTCUser)}>
                            create
                        </Button>
                    </div>

                    <Button
                        type="tertiary"
                        onClick={() => {
                            history.push('/login');
                        }}
                        data-testid="cancelOnboardNonSTCUserBtn"
                    >
                        cancel
                    </Button>
                </div>
            </form>
        </EntryWrapper>
    );
};

export default CreateInternalCiscoUserPage;
