// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navbar-moduleYyFm91B4bUC7kFr5uZdX{align-items:center;background-color:#2b5592;display:flex;height:60px;justify-content:space-between;position:fixed;width:100%;z-index:1}.navbar-modulegnalBFuyDVUrHqVnK8Mg{align-items:center;display:flex;flex-direction:row}.navbar-modulehLnJ3SbJosdpTOewQSSP{color:#fff;font-size:30px;padding-left:20px}.navbar-moduleW4jS_WHHZnGzIKJLESuk{color:#fff;padding:0 40px}", "",{"version":3,"sources":["webpack://./src/pages/dashboardPage/components/navbar/navbar.module.scss","webpack://./src/styles/variables/_colors.scss"],"names":[],"mappings":"AAEA,mCACI,kBAAA,CACA,wBCaG,CDZH,YAAA,CACA,WAAA,CACA,6BAAA,CACA,cAAA,CACA,UAAA,CACA,SAAA,CAGJ,mCACI,kBAAA,CACA,YAAA,CACA,kBAAA,CAGJ,mCACI,UCpBI,CDqBJ,cAAA,CACA,iBAAA,CAGJ,mCACI,UC1BI,CD2BJ,cAAA","sourcesContent":["@import 'src/styles/variables/colors';\n\n.navContainer {\n    align-items: center;\n    background-color: $navy;\n    display: flex;\n    height: 60px;\n    justify-content: space-between;\n    position: fixed;\n    width: 100%;\n    z-index: 1;\n}\n\n.navigationActionsContainer {\n    align-items: center;\n    display: flex;\n    flex-direction: row;\n}\n\n.navLogo {\n    color: $white;\n    font-size: 30px;\n    padding-left: 20px;\n}\n\n.navEmail {\n    color: $white;\n    padding: 0 40px;\n}\n","$white: #ffffff;\n$neutral_05: #f2f2f2;\n$pico_metal: #c6c7ca;\n$kingly_cloud: #dfdfdf;\n$heather_grey: #9e9ea2;\n$midnight_badger: #58585b;\n$neutral: #39393b;\n$bubbles: #ebfaff;\n$grape: #5674b9;\n$brand_burn: #03688e;\n$brand: #049fd9;\n$teal: #00acc1;\n$purple: #9a4b9d;\n$coral_burn: #d94a3d;\n$amaranth: #cf2030;\n$success: #6cc04a;\n$warning: #fbab18;\n$navy: #2b5592;\n$neutral: #39393b;\n$xdr_color: #991d53;\n\n//magnetic\n$green7: #409618;\n$green9: #2b7a0c;\n$red7: #d4503b;\n$red8: #c24632;\n\n:export {\n    green7: $green7;\n    red7: $red7;\n    red8: $red8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"green7": "#409618",
	"red7": "#d4503b",
	"red8": "#c24632",
	"navContainer": "navbar-moduleYyFm91B4bUC7kFr5uZdX",
	"navigationActionsContainer": "navbar-modulegnalBFuyDVUrHqVnK8Mg",
	"navLogo": "navbar-modulehLnJ3SbJosdpTOewQSSP",
	"navEmail": "navbar-moduleW4jS_WHHZnGzIKJLESuk"
};
export default ___CSS_LOADER_EXPORT___;
