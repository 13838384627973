import crypto from 'crypto';

// OKTA code generation and redirection functions
export const generateCodeVerifier = (str) => {
    return str
        .toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
};

function generateCodeChallenge(buffer) {
    return crypto.createHash('sha256').update(buffer).digest();
}

export const redirectToOkta = () => {
    const codeVerifier = generateCodeVerifier(crypto.randomBytes(64));
    localStorage.setItem('codeVerifier', codeVerifier);
    const codeChallenge = generateCodeVerifier(
        generateCodeChallenge(codeVerifier),
    );

    const redirectOktaUrl = `${process.env.REACT_APP_BFG_OKTA_URL}/oauth2/default/v1/authorize?client_id=${process.env.REACT_APP_BFG_OKTA_CLIENT_ID}&response_type=code&response_mode=query&scope=${process.env.REACT_APP_OKTA_SCOPES}&redirect_uri=${process.env.REACT_APP_BFG_OKTA_REDIRECT_URI}&state=auth&code_challenge_method=S256&code_challenge=${codeChallenge}`;
    window.location.replace(`${redirectOktaUrl}`);
};
