/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../common/constants';
import apiV1 from '../../services/axiosConfig/apiV1';

const initialState = {
    getOktaTokenStatus: FETCH_STATUS.INIT,
    getOktaTokenResult: {},
};

export const getOktaIdToken = createAsyncThunk(
    'oktaPage/getOktaIdToken',
    async ({ payload }, { rejectWithValue }) => {
        const convertedPayload = new URLSearchParams(payload);

        try {
            return await apiV1.sendRequest(
                `${process.env.REACT_APP_BFG_OKTA_URL}/oauth2/default/v1/token`,
                'post',
                convertedPayload.toString(),
                { disableWithCreds: true },
            );
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

const oktaSlice = createSlice({
    name: 'oktaSlice',
    initialState,
    reducers: {},
    extraReducers: {
        [getOktaIdToken.pending]: (state) => {
            state.getOktaTokenStatus = FETCH_STATUS.FETCHING;
        },
        [getOktaIdToken.fulfilled]: (state, action) => {
            state.getOktaTokenStatus = FETCH_STATUS.SUCCESS;
            state.getOktaTokenResult = action.payload;
        },
        [getOktaIdToken.rejected]: (state, action) => {
            state.getOktaTokenStatus = FETCH_STATUS.ERROR;
            state.getOktaTokenResult = action.payload;
        },
    },
});

export default oktaSlice.reducer;
