import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import {
    Delete as DeleteIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material';

import ModalContainer from '../../../../../../../../components/modalContainer/ModalContainer';
import { userRolesSliceActions } from '../../../../../../../../slices/userRoles/userRolesSlice';
import { FETCH_STATUS } from '../../../../../../../../common/constants';

const GridActions = ({ row }) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const { deleteRole } = userRolesSliceActions();
    const { deleteRoleStatus } = useSelector((state) => state.userRoles);

    const history = useHistory();

    useEffect(() => {
        if (deleteRoleStatus === FETCH_STATUS.SUCCESS) {
            setIsAlertOpen(false);
        }
    }, [deleteRoleStatus]);

    const handleAlertClose = () => {
        setIsAlertOpen(false);
    };

    return (
        <div data-testid="actionsCell">
            <ModalContainer
                isOpen={isAlertOpen}
                handleClose={() => handleAlertClose()}
                isFetching={deleteRoleStatus === FETCH_STATUS.FETCHING}
                submitBtnAction={() => deleteRole(row.id)}
                submitBtnName="delete"
                submitBtnVariant="destructive"
                cancelBtnAction={() => handleAlertClose()}
                cancelBtnName="cancel"
            >
                <h2>Delete role</h2>

                <h3>
                    Are you sure you want to delete role:{' '}
                    <span>{row.name}</span>
                </h3>
            </ModalContainer>

            <IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    history.replace(`user-roles/${row.id}`);
                }}
            >
                <SettingsIcon />
            </IconButton>

            <IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    setIsAlertOpen(true);
                }}
            >
                <DeleteIcon />
            </IconButton>
        </div>
    );
};

export default GridActions;
