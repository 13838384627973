import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import GridActions from './components/gridActions/GridActions';
import { userRolesSliceActions } from '../../../../../../slices/userRoles/userRolesSlice';
import { FETCH_STATUS } from '../../../../../../common/constants';

export const useRoles = () => {
    const { getAllRolesResult, getAllRolesStatus } = useSelector(
        (state) => state.userRoles,
    );

    const { getAllRoles } = userRolesSliceActions();

    useEffect(() => {
        getAllRoles();
    }, []);

    return {
        data: getAllRolesResult,
        loading: getAllRolesStatus === FETCH_STATUS.FETCHING,
    };
};

const commonProps = {
    headerAlign: 'center',
    align: 'center',
    sortable: false,
};

export const columns = [
    {
        ...commonProps,
        field: 'name',
        headerName: 'Name',
        headerAlign: 'left',
        flex: 1,
        align: 'left',
    },

    {
        ...commonProps,
        field: 'actions',
        headerName: '',
        headerAlign: 'right',
        align: 'right',
        flex: 1,
        renderCell: ({ row }) => <GridActions row={row} />,
    },
];
