/* eslint-disable no-param-reassign */
import {
    bindActionCreators,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { updateAlertParams } from '../alert/alertSlice';
import { FETCH_STATUS } from '../../common/constants';
import apiV1 from '../../services/axiosConfig/apiV1';
import { errorHandler } from '../../common/utils';

const BASE_PREFIX = '/bfg/roles';

const initialState = {
    // get all roles
    getAllRolesStatus: FETCH_STATUS.INIT,
    getAllRolesResult: [],
    // get role permissions
    getRolePermissionsStatus: FETCH_STATUS.INIT,
    getRolePermissionsResult: [],
    // update role permissions
    updateRolePermissionsStatus: FETCH_STATUS.INIT,
    updateRolePermissionsResult: {},
    // get role features
    getRoleFeaturesStatus: FETCH_STATUS.INIT,
    getRoleFeaturesResult: [],
    // get role
    getRoleStatus: FETCH_STATUS.INIT,
    getRoleResult: {},
    // update role
    updateRoleStatus: FETCH_STATUS.INIT,
    // delete role
    deleteRoleStatus: FETCH_STATUS.INIT,
    deleteRoleResult: {},
    // create role
    createRoleStatus: FETCH_STATUS.INIT,
    createRoleResult: {},
    // feature default value
    featureDefaultValueStatus: FETCH_STATUS.INIT,
    featureDefaultValueResult: {},
    // feature default value
    updateRoleFeaturesStatus: FETCH_STATUS.INIT,
    updateRoleFeaturesResult: {},
};

export const getAllRoles = createAsyncThunk(
    'getAllRoles',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(`${BASE_PREFIX}`, 'get');
            return response.data.data;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );

            return rejectWithValue(err);
        }
    },
);

export const getRoleById = createAsyncThunk(
    'getRoleById',
    async (roleId, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${roleId}`,
                'get',
            );
            return response.data.data;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );

            return rejectWithValue(err);
        }
    },
);

export const getRolePermissions = createAsyncThunk(
    'getRolePermissions',
    async (roleId, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${roleId}/role-permissions`,
                'get',
            );

            return response.data.data;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );

            return rejectWithValue(err);
        }
    },
);

export const updateRolePermissions = createAsyncThunk(
    'updateRolePermissions',
    async ({ roleId, payload }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${roleId}/role-permissions`,
                'put',
                payload,
            );

            const message = 'Data updated successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response.data.data;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );

            return rejectWithValue(err);
        }
    },
);

export const setFeatureDefaultValue = createAsyncThunk(
    'setFeatureDefaultValue',
    async ({ featureId, payload }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `bfg/features/${featureId}`,
                'put',
                payload,
            );

            const message = 'Default value updated successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response.data.data;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const updateRoleFeatures = createAsyncThunk(
    'updateRoleFeatures',
    async ({ roleId, payload }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${roleId}/role-features`,
                'put',
                payload,
            );

            const message = 'Data updated successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response.data.data;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );

            return rejectWithValue(err);
        }
    },
);

export const getRoleFeatures = createAsyncThunk(
    'getRoleFeatures',
    async ({ roleId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${roleId}/role-features`,
                'get',
            );

            return response.data.data;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );

            return rejectWithValue(err);
        }
    },
);

export const createRole = createAsyncThunk(
    'createRole',
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}`,
                'post',
                payload,
            );

            const message = 'Role created successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response.data.data;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );

            return rejectWithValue(err);
        }
    },
);

export const updateRole = createAsyncThunk(
    'updateRole',
    async ({ payload, roleId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${roleId}`,
                'put',
                payload,
            );

            const message = 'Role updated successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response.data.data.item;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );

            return rejectWithValue(err);
        }
    },
);

export const deleteRole = createAsyncThunk(
    'deleteRole',
    async (roleId, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${roleId}`,
                'delete',
            );

            const message = 'Role deleted successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));

            return response;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );

            return rejectWithValue(err);
        }
    },
);

const userRolesSlice = createSlice({
    name: 'userRolesSlice',
    initialState,
    reducers: {
        resetRoleStatus: (state) => {
            state.createRoleStatus = FETCH_STATUS.INIT;
        },
        resetFeatureDefaultValueStatus: (state) => {
            state.featureDefaultValueStatus = FETCH_STATUS.INIT;
        },
        resetDeleteRoleStatus: (state) => {
            state.deleteRoleStatus = FETCH_STATUS.INIT;
        },
    },
    extraReducers: {
        // get all roles
        [getAllRoles.pending]: (state) => {
            state.getAllRolesStatus = FETCH_STATUS.FETCHING;
        },
        [getAllRoles.fulfilled]: (state, action) => {
            state.getAllRolesStatus = FETCH_STATUS.SUCCESS;
            state.getAllRolesResult = action.payload;
        },
        [getAllRoles.rejected]: (state) => {
            state.getAllRolesStatus = FETCH_STATUS.ERROR;
        },
        // get role
        [getRoleById.pending]: (state) => {
            state.getRoleStatus = FETCH_STATUS.FETCHING;
        },
        [getRoleById.fulfilled]: (state, action) => {
            state.getRoleStatus = FETCH_STATUS.SUCCESS;
            state.getRoleResult = action.payload;
        },
        [getRoleById.rejected]: (state) => {
            state.getRoleStatus = FETCH_STATUS.ERROR;
        },
        // get role permissions
        [getRolePermissions.pending]: (state) => {
            state.getRolePermissionsStatus = FETCH_STATUS.FETCHING;
        },
        [getRolePermissions.fulfilled]: (state, action) => {
            state.getRolePermissionsStatus = FETCH_STATUS.SUCCESS;
            state.getRolePermissionsResult = action.payload;
        },
        [getRolePermissions.rejected]: (state) => {
            state.getRolePermissionsStatus = FETCH_STATUS.ERROR;
        },
        // update role permissions
        [updateRolePermissions.pending]: (state) => {
            state.updateRolePermissionsStatus = FETCH_STATUS.FETCHING;
        },
        [updateRolePermissions.fulfilled]: (state, action) => {
            state.updateRolePermissionsResult = action.payload;
            state.updateRolePermissionsStatus = FETCH_STATUS.SUCCESS;
        },
        [updateRolePermissions.rejected]: (state) => {
            state.updateRolePermissionsStatus = FETCH_STATUS.ERROR;
        },
        // get role features
        [getRoleFeatures.pending]: (state) => {
            state.getRoleFeaturesStatus = FETCH_STATUS.FETCHING;
        },
        [getRoleFeatures.fulfilled]: (state, action) => {
            state.getRoleFeaturesStatus = FETCH_STATUS.SUCCESS;
            state.getRoleFeaturesResult = action.payload;
        },
        [getRoleFeatures.rejected]: (state) => {
            state.getRoleFeaturesStatus = FETCH_STATUS.ERROR;
        },
        // update role
        [updateRole.pending]: (state) => {
            state.updateRoleStatus = FETCH_STATUS.FETCHING;
        },
        [updateRole.fulfilled]: (state, action) => {
            state.updateRoleStatus = FETCH_STATUS.SUCCESS;
            state.getRoleResult = action.payload;
        },
        [updateRole.rejected]: (state) => {
            state.updateRoleStatus = FETCH_STATUS.ERROR;
        },
        // delete role
        [deleteRole.pending]: (state) => {
            state.deleteRoleStatus = FETCH_STATUS.FETCHING;
        },
        [deleteRole.fulfilled]: (state, action) => {
            state.deleteRoleStatus = FETCH_STATUS.SUCCESS;
            const deletedRoleId = action.payload.data.data.item.id;
            state.getAllRolesResult = state.getAllRolesResult.filter(
                (role) => role.id !== deletedRoleId,
            );
        },
        [deleteRole.rejected]: (state, action) => {
            state.deleteRoleStatus = FETCH_STATUS.ERROR;
            state.deleteRoleResult = action?.payload;
        },
        // create role
        [createRole.pending]: (state) => {
            state.createRoleStatus = FETCH_STATUS.FETCHING;
        },
        [createRole.fulfilled]: (state, action) => {
            state.createRoleStatus = FETCH_STATUS.SUCCESS;
            state.createRoleResult = action.payload;
            const newRole = action.payload;
            state.getAllRolesResult = [newRole, ...state.getAllRolesResult];
        },
        [createRole.rejected]: (state) => {
            state.createRoleStatus = FETCH_STATUS.ERROR;
        },
        // feature default value
        [setFeatureDefaultValue.pending]: (state) => {
            state.featureDefaultValueStatus = FETCH_STATUS.FETCHING;
        },
        [setFeatureDefaultValue.fulfilled]: (state, action) => {
            state.featureDefaultValueResult = action.payload;
            state.featureDefaultValueStatus = FETCH_STATUS.SUCCESS;
        },
        [setFeatureDefaultValue.rejected]: (state) => {
            state.featureDefaultValueStatus = FETCH_STATUS.ERROR;
        },
        // update role features
        [updateRoleFeatures.pending]: (state) => {
            state.updateRoleFeaturesStatus = FETCH_STATUS.FETCHING;
        },
        [updateRoleFeatures.fulfilled]: (state, action) => {
            state.updateRoleFeaturesStatus = FETCH_STATUS.SUCCESS;
            state.updateRoleFeaturesResult = action.payload;
        },
        [updateRoleFeatures.rejected]: (state) => {
            state.updateRoleFeaturesStatus = FETCH_STATUS.ERROR;
        },
    },
});

export const {
    resetRoleStatus,
    resetFeatureDefaultValueStatus,
    resetDeleteRoleStatus,
} = userRolesSlice.actions;

export const userRolesSliceActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(
        {
            getAllRoles,
            getRolePermissions,
            deleteRole,
            getRoleById,
        },
        dispatch,
    );
};

export default userRolesSlice.reducer;
