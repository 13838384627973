// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".noPageFoundsK1nbHP0qa4IIK6D8vfX{align-items:center;display:flex;flex-direction:column;height:100vh;justify-content:center;text-align:center}.noPageFoundsK1nbHP0qa4IIK6D8vfX p{font-size:30px;font-weight:bold}.noPageFoundsK1nbHP0qa4IIK6D8vfX p:not(:last-child){margin-bottom:15px}.noPageFoundzNUIZPtiKb9aCtIfWPxw{margin-top:40px}", "",{"version":3,"sources":["webpack://./src/components/noPageFound/noPageFound.scss"],"names":[],"mappings":"AAEA,iCACI,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA,CACA,iBAAA,CAEA,mCACI,cAAA,CACA,gBAAA,CAGJ,oDACI,kBAAA,CAIR,iCACI,eAAA","sourcesContent":["@import '../../styles/variables/colors';\n\n.wrapper {\n    align-items: center;\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n    justify-content: center;\n    text-align: center;\n\n    p {\n        font-size: 30px;\n        font-weight: bold;\n    }\n\n    p:not(:last-child) {\n        margin-bottom: 15px;\n    }\n}\n\n.btn {\n    margin-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"green7": "#409618",
	"red7": "#d4503b",
	"red8": "#c24632",
	"wrapper": "noPageFoundsK1nbHP0qa4IIK6D8vfX",
	"btn": "noPageFoundzNUIZPtiKb9aCtIfWPxw"
};
export default ___CSS_LOADER_EXPORT___;
