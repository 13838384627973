import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox as CheckboxComponent, FormControlLabel } from '@mui/material';

const Checkbox = ({ name, label, options = {}, ...rest }) => {
    const { register, control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                return (
                    <FormControlLabel
                        label={label}
                        labelPlacement={label ? 'end' : 'top'}
                        control={
                            <CheckboxComponent
                                {...register(name, options)}
                                disableRipple
                                {...field}
                                {...rest}
                            />
                        }
                    />
                );
            }}
        />
    );
};

export default Checkbox;
