import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import LoginPage from './pages/loginPage/LoginPage';
import DashboardPage from './pages/dashboardPage/DashboardPage';
import {
    refreshToken,
    resetBfgOktaLoginStatus,
    resetLoginResult,
    resetUserInfo,
    setTokenInfo,
} from './pages/loginPage/loginPageSlice';
import CreateInternalCiscoUserPage from './pages/onboardUserPage/OnboardUserPage';
import OktaPage from './pages/oktaPage/OktaPage';
import { theme } from './styles/theme';
import { FETCH_STATUS } from './common/constants';
import NoPageFound from './components/noPageFound/NoPageFound';

const App = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [refreshTime, setRefreshTime] = useState(1800000);

    const {
        refreshTokenResult,
        location,
        logOutStatus,
        bfgOktaLoginStatus,
        tokenInfo,
        refreshTokenStatus,
    } = useSelector((state) => state?.login);

    const currentLocation = history.location.pathname.split('/');

    useEffect(() => {
        const isLocal =
            process.env.REACT_APP_STAC_HOST !== 'http://localhost:3333';

        if (!window.location.host.startsWith('www') && isLocal) {
            window.location =
                window.location.protocol +
                '//' +
                'www.' +
                window.location.host +
                window.location.pathname;

            return;
        }
        dispatch(refreshToken());
    }, []);

    useEffect(() => {
        if (logOutStatus === FETCH_STATUS.SUCCESS) {
            setIsLoggedIn(false);
            dispatch(resetUserInfo());
            dispatch(resetLoginResult());
            dispatch(resetBfgOktaLoginStatus());
            history.push('/login');
        }
    }, [logOutStatus]);

    useEffect(() => {
        if (refreshTokenResult?.data?.data?.accessToken) {
            const decodedJWT = JSON.parse(
                Buffer.from(
                    refreshTokenResult?.data?.data?.accessToken.split('.')[1],
                    'base64',
                ).toString(),
            );

            dispatch(setTokenInfo(decodedJWT));
            setIsLoggedIn(true);
        }

        if (bfgOktaLoginStatus === FETCH_STATUS.SUCCESS) {
            setIsLoggedIn(true);
        }

        if (!refreshTokenResult?.data?.data?.accessToken && isLoggedIn) {
            setIsLoggedIn(false);
            history.push('/login');
        }
    }, [refreshTokenResult, bfgOktaLoginStatus]);

    const refreshTokenFrequency = (startTime, expireTime) => {
        const difference =
            Math.abs(startTime?.getTime() - expireTime?.getTime()) / 2;

        setRefreshTime(difference);
    };

    useEffect(() => {
        if (tokenInfo?.iat) {
            refreshTokenFrequency(
                new Date(tokenInfo.iat * 1000),
                new Date(tokenInfo.exp * 1000),
            );
        }
    }, [tokenInfo]);

    useEffect(() => {
        if (isLoggedIn) {
            setInterval(() => {
                dispatch(refreshToken());
            }, refreshTime);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (
            !currentLocation.find(
                (item) =>
                    item === 'login' || item === 'okta' || item === 'onboard',
            ) &&
            !refreshTokenResult?.data?.data?.accessToken &&
            refreshTokenStatus === FETCH_STATUS.SUCCESS
        ) {
            history.push('/login');
        }
    }, [refreshTokenStatus]);

    return (
        <ThemeProvider theme={theme}>
            {!isLoggedIn ? (
                <>
                    <Route exact path="/">
                        <Redirect exact from="/" to="/login" />
                    </Route>

                    <Route exact path="/login">
                        <LoginPage />
                    </Route>
                    <Route exact path="/okta">
                        <OktaPage />
                    </Route>
                    {/* Onboard Page */}
                    <Route exact path="/onboard/:hex">
                        <CreateInternalCiscoUserPage />
                    </Route>
                </>
            ) : (
                <Switch>
                    <Route path="/dashboard">
                        <DashboardPage />
                    </Route>

                    <Route exact path="/login">
                        <Redirect exact from="/login" to={location} />
                    </Route>
                    <Route path="*">
                        <NoPageFound location={location} isUpperRoute />
                    </Route>
                </Switch>
            )}
        </ThemeProvider>
    );
};

export default App;
