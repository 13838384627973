import React, { lazy, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoadingDots from '@opendns/dpl-loading-dots';
import { Box, Container, Grid } from '@mui/material';

import NavbarDashboard from './components/navbar/Navbar';
import UserRoles from './components/userRoles/UserRoles';
import SideNav from './components/sideNav/SideNav';
import IdleModal from '../../components/idleModal/IdleModal';
import InactivityTimer from '../../services/InactivityTimer';
import NoPageFound from '../../components/noPageFound/NoPageFound';
import { FETCH_STATUS } from '../../common/constants';
import Alert from '../../components/alert/Alert';
import { getMeInformation, logOut } from '../loginPage/loginPageSlice';

import g from '../../styles/globalStyles.module.scss';

const AccountDetails = lazy(() =>
    import(
        './components/onboarding/accountsGrid/accountDetails/AccountDetails'
    ),
);
const UserOnboardingComponent = lazy(() =>
    import('./components/onboarding/Onboarding'),
);
const ParentOrgsList = lazy(() =>
    import('./components/organizations/parentOrgList/ParentOrgsList'),
);
const ParentOrgDetails = lazy(() =>
    import('./components/organizations/parentOrgDetails/ParentOrgDetails'),
);
const AuditLogsComponent = lazy(() => import('./components/auditLog/AuditLog'));
const CustomerDetails = lazy(() =>
    import('./components/customers/customerDetails/CustomerDetails'),
);
const Messages = lazy(() => import('./components/messages/Messages'));
const RoleDetails = lazy(() =>
    import('./components/userRoles/components/roleDetails/RoleDetails'),
);
const Features = lazy(() => import('./components/features/Features'));
const Permissions = lazy(() => import('./components/permissions/Permissions'));
const Migrations = lazy(() =>
    import('./components/parentOrgsMigration/ParentOrgsMigration'),
);

const DashboardPage = () => {
    const { path } = useRouteMatch();
    const dispatch = useDispatch();

    const [isInactive, setIsInactive] = useState(false);

    const { logOutStatus } = useSelector((state) => state?.login);

    useEffect(() => {
        dispatch(getMeInformation());
        const timer = new InactivityTimer({
            // timeout value in minutes
            timeout: 10,
            onTimeout: () => {
                setIsInactive(true);
            },
        });

        return () => {
            timer.cleanUp();
        };
    }, []);

    const handleInactivity = () => {
        if (localStorage.getItem('isSingOutDueInactive')) {
            dispatch(logOut());
        }
    };
    useEffect(() => {
        window.addEventListener('storage', handleInactivity);
        return () => document.removeEventListener('storage', handleInactivity);
    });

    return (
        <Grid sx={{ position: 'relative' }}>
            {isInactive && <IdleModal setIsInactive={setIsInactive} />}

            <NavbarDashboard />

            <Box display="flex" flexGrow={1}>
                <SideNav />

                <Container
                    maxWidth={false}
                    disableGutters
                    sx={{
                        mt: '60px',
                        px: '40px',
                        overflow: 'auto',
                        position: 'relative',
                        height: 'calc(100vh - 60px)',
                        width: '100%',
                    }}
                >
                    <Alert />

                    <React.Suspense
                        fallback={
                            <div className={g.contentPreloaderWithBlur}>
                                <LoadingDots size="small" />
                            </div>
                        }
                    >
                        <Switch>
                            <Route exact path={`${path}/organizations`}>
                                <ParentOrgsList />
                            </Route>

                            <Route exact path={`${path}/organizations/:orgId`}>
                                <ParentOrgDetails />
                            </Route>

                            <Route
                                exact
                                path={`${path}/organizations/:parentOrgId/customers/:customerId`}
                            >
                                <CustomerDetails />
                            </Route>

                            <Route exact path={`${path}/accounts`}>
                                <UserOnboardingComponent />
                            </Route>

                            <Route exact path={`${path}/accounts/:id`}>
                                <AccountDetails />
                            </Route>
                            <Route exact path={`${path}/messages`}>
                                <Messages />
                            </Route>

                            <Route exact path={`${path}/user-roles`}>
                                <UserRoles />
                            </Route>

                            <Route exact path={`${path}/user-roles/:roleId`}>
                                <RoleDetails />
                            </Route>

                            <Route exact path={`${path}/permissions`}>
                                <Permissions />
                            </Route>

                            <Route exact path={`${path}/features`}>
                                <Features />
                            </Route>

                            <Route exact path={`${path}/auditlogs`}>
                                <AuditLogsComponent />
                            </Route>
                            <Route exact path={`${path}/migrations`}>
                                <Migrations />
                            </Route>

                            <Route path="*">
                                <NoPageFound />
                            </Route>
                        </Switch>
                    </React.Suspense>
                </Container>
            </Box>

            {logOutStatus === FETCH_STATUS.FETCHING && (
                <div
                    data-testid="dashboardPagePreloader"
                    className={g.contentPreloaderWithBlur}
                >
                    <LoadingDots size="large" />
                </div>
            )}
        </Grid>
    );
};

export default DashboardPage;
