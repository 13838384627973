import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    AdminPanelSettings,
    ChevronLeft,
    ChevronRight,
    FormatListBulleted,
    Forum,
    Group,
    LibraryBooks,
    Logout,
    ManageAccounts,
    PlaylistAddCheck,
} from '@mui/icons-material';

import { logOut } from '../../../loginPage/loginPageSlice';

import s from './sideNav.module.scss';

const SideNav = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isNavHide, setIsNavHide] = useState(false);

    const navHandler = () => {
        setIsNavHide((prevState) => !prevState);
    };

    const menuArr = [
        {
            id: 1,
            title: 'Accounts',
            icon: <Group fontSize="medium" color="primary" />,
            action: () => history.replace('/dashboard/accounts'),
            isActive: history.location.pathname === '/dashboard/accounts',
            dataTestId: '',
        },
        {
            id: 2,
            title: 'Messages',
            icon: <Forum fontSize="medium" color="primary" />,
            action: () => history.replace('/dashboard/messages'),
            isActive: history.location.pathname === '/dashboard/messages',
            dataTestId: '',
        },
        {
            id: 3,
            title: 'User roles',
            icon: <ManageAccounts fontSize="medium" color="primary" />,
            action: () => history.replace('/dashboard/user-roles'),
            isActive: history.location.pathname === '/dashboard/user-roles',
            dataTestId: '',
        },
        {
            id: 4,
            title: 'Permissions',
            icon: <AdminPanelSettings fontSize="medium" color="primary" />,
            action: () => history.replace('/dashboard/permissions'),
            isActive: history.location.pathname === '/dashboard/permissions',
            dataTestId: '',
        },
        {
            id: 5,
            title: 'Features',
            icon: <PlaylistAddCheck fontSize="medium" color="primary" />,
            action: () => history.replace('/dashboard/features'),
            isActive: history.location.pathname === '/dashboard/features',
            dataTestId: '',
        },
        {
            id: 6,
            title: 'Audit Log',
            icon: <FormatListBulleted fontSize="medium" color="primary" />,
            action: () => history.replace('/dashboard/auditlogs'),
            isActive: history.location.pathname === '/dashboard/auditlogs',
            dataTestId: '',
        },
        {
            id: 7,
            title: 'Organizations',
            icon: <LibraryBooks fontSize="medium" color="primary" />,
            action: () => history.replace('/dashboard/organizations'),
            isActive: history.location.pathname === '/dashboard/organizations',
            dataTestId: '',
        },
        // Commented while not needed
        // {
        //     id: 8,
        //     title: 'Migrations',
        //     icon: <CloudDownload fontSize="medium" color="primary" />,
        //     action: () => history.replace('/dashboard/migrations'),
        //     isActive: history.location.pathname === '/dashboard/migrations',
        //     dataTestId: '',
        // },
        {
            id: 9,
            title: 'Logout',
            icon: <Logout fontSize="medium" color="primary" />,
            action: () => dispatch(logOut()),
            isActive: false,
            dataTestId: '',
        },
    ];

    return (
        <div
            className={`${s.sideNavWrapper}${
                isNavHide ? ` ${s.menuHide}` : ''
            }`}
        >
            <button
                type="button"
                className={s.toggler}
                onClick={() => navHandler()}
                data-testid="menuToggler"
            >
                {isNavHide ? (
                    <ChevronRight fontSize="large" color="primary" />
                ) : (
                    <ChevronLeft fontSize="large" color="primary" />
                )}
            </button>

            <div className={s.sideNav} data-testid="appSideMenu">
                <h2 className={s.sideNavTitle}>Menu</h2>

                <ul>
                    {menuArr.map((el) => {
                        const active = el.isActive ? `${s.active}` : '';
                        return (
                            <li className={active} key={el.id}>
                                <button type="button" onClick={el.action}>
                                    {el.icon}
                                    <span className={s.sideItemText}>
                                        {el.title}
                                    </span>
                                </button>
                            </li>
                        );
                    })}
                </ul>

                <div className={s.linksWrapper}>
                    <a
                        href="https://stchelp.ciscosecure.com/#!g_welcome-to-stc.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Documentation
                    </a>

                    <h3>© Cisco Systems</h3>
                </div>
            </div>
        </div>
    );
};

export default SideNav;
