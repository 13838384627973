export const FETCH_STATUS = {
    INIT: 'INITIAL',
    FETCHING: 'FETCHING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const TRIAL_STATUS_VALUES = {
    ANY: 'any',
    ACTIVE_PENDING: 'activePending',
    ACTIVE: 'active',
    PENDING: 'pending',
    EXPIRED: 'expired',
    CONVERTED: 'converted',
    DISABLED: 'disabled',
    DEFAULT_SELECTED: '',
};

export const SORT_MODE = {
    ASC: 'asc',
    DESC: 'desc',
};

export const REQUEST = {
    CUSTOM_FIELD_ORIGINCOUNTS: 'originCounts',
    CUSTOM_FIELD_LASTLOGIN: 'lastLogin',
    CUSTOM_FIELD_ORIGINCOUNTSCSV: 'originCountsCsv',
    STATUS: {
        ACTIVE: 'active',
        HOLD: 'hold',
    },
};

export const DEBOUNCE_INPUT_TIME = 400;

export const REQUIRED_MESSAGE = 'Required';

export const IS_PASSWORD_REQUIRED = false;

export const PRODUCT_NAMES = {
    umbrella: 'Umbrella',
    secureEndpoints: 'Secure Endpoint',
    xdr: 'XDR',
    cmd: 'CMD',
};

export const ORG_TYPES = [
    {
        value: '1',
        label: 'Partner',
    },
    {
        value: '2',
        label: 'Internal seller',
    },
    {
        value: '3',
        label: 'Distributor',
    },
];

export const XDR_REGIONS = [
    {
        value: '13',
        sku: 'XDR-ADV',
        label: 'North America',
    },
    {
        value: '14',
        sku: 'XDR-ADV-EU',
        label: 'Europe',
    },
    {
        value: '15',
        sku: 'XDR-ADV-AP',
        label: 'APJC',
    },
];
