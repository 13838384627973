import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from '../App';
import store from '../store';

import '../styles/index.scss';
import '@magnetic/core/dist/esm/style.css';
import '@magnetic/core/dist/cjs/style.css';

require('@ciscodesignsystems/cds-react-date-time-picker/index.css');
require('@ciscodesignsystems/cds-magnetic-theme-web/css/dist/token-theme-classic-light-variables.css');

const rootEl = document.getElementById('appRoot');

render(
    <Provider store={store}>
        <BrowserRouter basename="/">
            <App />
        </BrowserRouter>
    </Provider>,

    rootEl,
);
