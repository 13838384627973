/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../common/constants';
import apiV1 from '../../services/axiosConfig/apiV1';

const initialState = {
    loginStatus: FETCH_STATUS.INIT,
    refreshTokenStatus: FETCH_STATUS.INIT,
    logOutStatus: FETCH_STATUS.INIT,
    forgotPasswordStatus: FETCH_STATUS.INIT,
    forgotPasswordResponse: {},
    refreshTokenResult: {},
    loginResult: {},
    userInfo: {},
    tokenInfo: {},
    resetPasswordEmail: '',
    isSingOutDueInactive: false,
    location: '/dashboard/accounts', // where to redirect on initial loading
    bfgOktaLoginStatus: FETCH_STATUS.INIT,
    bfgOktaLoginResult: {},
};

export const bfgOktaLogin = createAsyncThunk(
    'loginPage/bfgOktaLogin',
    async ({ payload }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest('/bfg/login/okta', 'post', payload);
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getMeInformation = createAsyncThunk(
    'loginPage/getMeInformation',
    async (_, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest('/bfg/users/me');
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const logOut = createAsyncThunk(
    'loginPage/logOut',
    async (_, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest('/logout', 'post');
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const sendForgotPassword = createAsyncThunk(
    'loginPage/sendForgotPassword',
    async ({ payload }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest('/forgot', 'post', payload);
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const refreshToken = createAsyncThunk(
    'loginPage/refreshToken',
    async (_, { rejectWithValue }) => {
        try {
            return await apiV1.refreshToken();
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

const loginPageSlice = createSlice({
    name: 'loginPageSlice',
    initialState,
    reducers: {
        setLocation(state, action) {
            return {
                ...state,
                location: action?.payload,
            };
        },
        setTokenInfo(state, action) {
            return {
                ...state,
                tokenInfo: action.payload,
            };
        },
        resetUserInfo(state) {
            return {
                ...state,
                userInfo: {},
            };
        },
        resetRefreshToken(state) {
            return {
                ...state,
                refreshTokenResult: {},
                refreshTokenStatus: FETCH_STATUS.INIT,
            };
        },
        resetLoginResult(state) {
            return {
                ...state,
                loginResult: {},
            };
        },
        resetBfgOktaLoginStatus(state) {
            return {
                ...state,
                bfgOktaLoginStatus: FETCH_STATUS.INIT,
            };
        },
    },
    extraReducers: {
        // LogOut section
        [logOut.pending]: (state) => {
            state.logOutStatus = FETCH_STATUS.FETCHING;
        },
        [logOut.fulfilled]: (state) => {
            state.logOutStatus = FETCH_STATUS.SUCCESS;
        },
        // forgot password section
        [sendForgotPassword.pending]: (state) => {
            state.forgotPasswordStatus = FETCH_STATUS.FETCHING;
        },
        [sendForgotPassword.fulfilled]: (state, action) => {
            state.forgotPasswordStatus = FETCH_STATUS.SUCCESS;
            state.forgotPasswordResponse = action.payload;
        },
        [sendForgotPassword.rejected]: (state, action) => {
            state.forgotPasswordStatus = FETCH_STATUS.ERROR;
            state.forgotPasswordResponse = action.payload;
        },
        // refresh token section
        [refreshToken.pending]: (state) => {
            state.refreshTokenStatus = FETCH_STATUS.FETCHING;
        },

        [refreshToken.fulfilled]: (state, action) => {
            state.refreshTokenStatus = FETCH_STATUS.SUCCESS;
            state.refreshTokenResult = action?.payload;
        },
        [refreshToken.rejected]: (state) => {
            state.refreshTokenStatus = FETCH_STATUS.ERROR;
        },

        // okta login
        [bfgOktaLogin.pending]: (state) => {
            state.bfgOktaLoginStatus = FETCH_STATUS.FETCHING;
        },
        [bfgOktaLogin.fulfilled]: (state, action) => {
            state.bfgOktaLoginStatus = FETCH_STATUS.SUCCESS;
            state.bfgOktaLoginResult = action.payload;
        },
        [bfgOktaLogin.rejected]: (state, action) => {
            state.bfgOktaLoginStatus = FETCH_STATUS.ERROR;
            state.bfgOktaLoginResult = action.payload;
        },

        // Me information
        [getMeInformation.fulfilled]: (state, action) => {
            state.userInfo = action.payload.data?.data;
        },
        [getMeInformation.rejected]: (state, action) => {
            state.userInfo = action.payload;
        },
    },
});

export const {
    setTokenInfo,
    resetRefreshToken,
    resetLoginResult,
    resetUserInfo,
    setLocation,
    resetBfgOktaLoginStatus,
} = loginPageSlice.actions;

export default loginPageSlice.reducer;
