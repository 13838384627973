import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClockIcon } from '@opendns/svg-icons';
import { useHistory } from 'react-router-dom';
import Button from '@opendns/dpl-button';

import { logOut } from '../../pages/loginPage/loginPageSlice';

import s from './idleModal.scss';

const IdleModal = ({ setIsInactive }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [count, setCount] = useState(59);

    const logOutHandler = () => {
        localStorage.setItem('isSingOutDueInactive', true);
        dispatch(logOut());
        history.push('/');
    };

    useEffect(() => {
        if (count > 0) {
            const secondsLeft = setInterval(() => {
                setCount((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(secondsLeft);
        }
        return logOutHandler();
    }, [count]);

    return (
        <div data-testid="areYouThereBtn" className={s.modalWrapper}>
            <div className={s.contentWrapper}>
                <ClockIcon size={60} color="p1" />
                <h2 className={s.title}>Are you Still there?</h2>
                <p
                    className={s.description}
                >{`For security reasons your session will expire due to inactivity in ${count} seconds.`}</p>
                <Button
                    data-testid="resumeBtn"
                    name="resumeBtn"
                    type="secondary"
                    onClick={() => {
                        setIsInactive(false);
                    }}
                >
                    Resume session
                </Button>
            </div>
        </div>
    );
};

export default IdleModal;
