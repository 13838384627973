/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../../../common/constants';
import apiV1 from '../../../../services/axiosConfig/apiV1';

const initialState = {
    createOrgStatus: FETCH_STATUS.INIT,
    createOrgResult: {},
    packages: [
        { value: '165', label: 'Partner Console' },
        {
            value: '296',
            label: 'Internal Sellers Console',
        },
        {
            value: '999',
            label: 'Distributor',
        },
    ],
    orgTypes: [{ value: '7', label: 'Partner' }],
};

export const createOrg = createAsyncThunk(
    'organization/createOrg',
    async (createOrgData, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(
                '/bfg/organizations/console/create',
                'post',
                createOrgData,
            );
            return response;
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

const createOrgComponentSlice = createSlice({
    name: 'createOrgSlice',
    initialState,
    reducers: {
        clearCreateOrgStatus(state) {
            return {
                ...state,
                createOrgStatus: FETCH_STATUS.INIT,
            };
        },
        clearCreateOrgResult(state) {
            return {
                ...state,
                createOrgResult: {},
            };
        },
    },
    extraReducers: {
        [createOrg.pending]: (state) => {
            state.createOrgStatus = FETCH_STATUS.FETCHING;
        },
        [createOrg.fulfilled]: (state, action) => {
            state.createOrgStatus = FETCH_STATUS.SUCCESS;
            state.createOrgResult = action?.payload;
        },
        [createOrg.rejected]: (state, action) => {
            state.createOrgStatus = FETCH_STATUS.ERROR;
            state.createOrgResult = action?.payload;
        },
    },
});

export const { clearCreateOrgResult, clearCreateOrgStatus } =
    createOrgComponentSlice.actions;

export default createOrgComponentSlice.reducer;
