import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

const SearchInput = ({ onSearch, placeHolder, autoFocus, value }) => {
    return (
        <TextField
            sx={{ width: '300px' }}
            autoFocus={autoFocus}
            value={value}
            placeholder={placeHolder}
            onInput={onSearch}
            variant="standard"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchInput;
