/* eslint-disable no-param-reassign */
import {
    bindActionCreators,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { updateAlertParams } from '../alert/alertSlice';
import { FETCH_STATUS } from '../../common/constants';
import apiV1 from '../../services/axiosConfig/apiV1';
import { errorHandler } from '../../common/utils';

const BASE_PREFIX = '/bfg/permissions';

const initialState = {
    getPermissionsStatus: FETCH_STATUS.INIT,
    getPermissionsResult: [],
    // create permission
    createPermissionStatus: FETCH_STATUS.INIT,
    createPermissionResult: {},
    // update permission
    updatePermissionStatus: FETCH_STATUS.INIT,
    updatePermissionResult: {},
    // delete permission
    deletePermissionStatus: FETCH_STATUS.INIT,
};

export const getPermissions = createAsyncThunk(
    'getPermissions',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(BASE_PREFIX, 'get');
            return response.data.data;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const createPermission = createAsyncThunk(
    'createPermission',
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                BASE_PREFIX,
                'post',
                payload,
            );
            const message = 'Permission created successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response.data.data;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const updatePermission = createAsyncThunk(
    'updatePermission',
    async ({ permissionId, payload }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${permissionId}`,
                'put',
                payload,
            );
            const message = 'Permission updated successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response.data.data;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const deletePermission = createAsyncThunk(
    'deletePermission',
    async ({ permissionId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${permissionId}`,
                'delete',
            );
            const message = 'Permission deleted successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response.data.data;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

const permissionsSlice = createSlice({
    name: 'permissionsSlice',
    initialState,
    reducers: {},
    extraReducers: {
        // create permission
        [createPermission.pending]: (state) => {
            state.createPermissionStatus = FETCH_STATUS.FETCHING;
        },
        [createPermission.fulfilled]: (state, action) => {
            state.createPermissionStatus = FETCH_STATUS.SUCCESS;
            state.createPermissionResult = action.payload;
            state.getPermissionsResult.unshift(action.payload);
        },
        [createPermission.rejected]: (state) => {
            state.createPermissionStatus = FETCH_STATUS.ERROR;
        },
        // get permissions
        [getPermissions.pending]: (state) => {
            state.getPermissionsStatus = FETCH_STATUS.FETCHING;
        },
        [getPermissions.fulfilled]: (state, action) => {
            state.getPermissionsStatus = FETCH_STATUS.SUCCESS;
            state.getPermissionsResult = action.payload;
        },
        [getPermissions.rejected]: (state) => {
            state.getPermissionsStatus = FETCH_STATUS.ERROR;
        },
        // delete permission
        [deletePermission.pending]: (state) => {
            state.deletePermissionStatus = FETCH_STATUS.FETCHING;
        },
        [deletePermission.fulfilled]: (state, action) => {
            state.deletePermissionStatus = FETCH_STATUS.SUCCESS;
            state.getPermissionsResult = state.getPermissionsResult.filter(
                (permission) => permission.id !== action.payload.item.id,
            );
        },
        [deletePermission.rejected]: (state) => {
            state.deletePermissionStatus = FETCH_STATUS.ERROR;
        },
        // update permission
        [updatePermission.pending]: (state) => {
            state.updatePermissionStatus = FETCH_STATUS.FETCHING;
        },
        [updatePermission.fulfilled]: (state, action) => {
            state.updatePermissionStatus = FETCH_STATUS.SUCCESS;
            const updatedItem = action.payload;
            const updatedMessageId = updatedItem.id;
            const record = state.getPermissionsResult.find(
                (msg) => msg.id === updatedMessageId,
            );

            Object.assign(record, updatedItem);
        },
        [updatePermission.rejected]: (state) => {
            state.updatePermissionStatus = FETCH_STATUS.ERROR;
        },
    },
});

export const permissionsSliceActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(
        {
            getPermissions,
        },
        dispatch,
    );
};

export default permissionsSlice.reducer;
