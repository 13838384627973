import { createTheme } from '@mui/material';

export const theme = createTheme({
    components: {
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: '1rem',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    bottom: '-20px',
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '0.8rem',
                    lineHeight: '1.25rem',
                },
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'space-between',

                    '.MuiButtonBase-root': {
                        borderRadius: '4px !important',
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                    '.MuiDataGrid-main': {
                        overflowY: 'auto',
                    },
                    '.MuiDataGrid-cell': { outline: 'none !important' },
                    '.MuiDataGrid-columnHeader': {
                        outline: 'none !important',
                    },
                },
            },
        },
    },
    typography: {
        h1: {
            fontWeight: '100',
            fontSize: '2.5rem',
            textAlign: 'center',
            fontFamily: 'CiscoSans',
        },
    },
    palette: {
        primary: {
            main: '#049fd9',
        },
        grey: {
            main: '#9e9e9e',
        },
    },
});
