import React from 'react';
import {
    Autocomplete as AutocompleteComponent,
    Checkbox,
    TextField,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const Autocomplete = ({ items = [], label, name, ...rest }) => {
    const { control } = useFormContext();

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange }, fieldState }) => {
                return (
                    <AutocompleteComponent
                        multiple
                        disableCloseOnSelect
                        options={items}
                        getOptionLabel={(option) => option.label}
                        onChange={(_e, data) => {
                            onChange(data);
                        }}
                        {...rest}
                        renderOption={(props, option, { selected }) => {
                            return (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.label}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                label={label}
                                error={!!fieldState.error}
                                helperText={
                                    fieldState.error &&
                                    (fieldState.error.message ||
                                        fieldState.error.type)
                                }
                            />
                        )}
                    />
                );
            }}
        />
    );
};

export default Autocomplete;
