import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as AlertComponent, Slide, Snackbar } from '@mui/material';

import { closeAlert } from '../../slices/alert/alertSlice';

const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
};

const Alert = () => {
    const dispatch = useDispatch();

    const { isOpen, message, severity } = useSelector((state) => state.alert);

    const onClose = () => {
        dispatch(closeAlert());
    };

    return (
        <Snackbar
            sx={{ position: 'fixed' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={isOpen}
            onClose={onClose}
            autoHideDuration={4000}
            TransitionComponent={TransitionUp}
            data-testid="snackMessage"
        >
            <AlertComponent severity={severity} onClose={onClose}>
                {message}
            </AlertComponent>
        </Snackbar>
    );
};

export default Alert;
