import React, { memo } from 'react';
import { Button, CircularProgress } from '@mui/material';

const SubmitButton = ({
    children,
    isLoading,
    spinnerSize = 25,
    spinnerColor = 'inherit',
    ...rest
}) => {
    return (
        <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            {...rest}
        >
            {isLoading && (
                <CircularProgress
                    size={spinnerSize}
                    color={spinnerColor}
                    sx={{ position: 'absolute' }}
                />
            )}
            <div style={{ opacity: isLoading ? 0 : 1 }}>{children}</div>
        </Button>
    );
};

export default memo(SubmitButton);
