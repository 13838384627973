import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@opendns/dpl-button';
import { AlertIcon } from '@opendns/svg-icons';

import s from './noPageFound.scss';

const NoPageFound = ({ location, isUpperRoute }) => {
    const history = useHistory();

    return (
        <div className={s.wrapper} data-testid="noPageFound">
            <AlertIcon color="s1" size={200} />

            <p>404</p>

            <p>Page Not Found</p>

            {isUpperRoute && (
                <div className={s.btn}>
                    <Button
                        data-testid="goHomeBtn"
                        type="primary"
                        onClick={() => {
                            history.push(location);
                        }}
                    >
                        Go Home
                    </Button>
                </div>
            )}
        </div>
    );
};

export default NoPageFound;
