import { combineReducers } from 'redux';

import alertSlice from '../slices/alert/alertSlice';
import messagesSlice from '../slices/messages/messagesSlice';
import userRolesSlice from '../slices/userRoles/userRolesSlice';
import featuresSlice from '../slices/features/featuresSlice';
import organizationFeaturesSlice from '../slices/features/organizationFeaturesSlice';
import organizationDefaultFeaturesSlice from '../slices/features/organizationDefaultFeaturesSlice';
import permissionsSlice from '../slices/permissions/permissionsSlice';
import auditLogSlice from '../pages/dashboardPage/components/auditLog/auditLogSlice';
import createOrgSlice from '../pages/dashboardPage/components/createOrgComponent/createOrgComponentSlice';
import loginPageSlice from '../pages/loginPage/loginPageSlice';
import onboardSlice from '../pages/dashboardPage/components/onboarding/onboardSlice';
import organizationsSlice from '../pages/dashboardPage/components/organizations/organizationsSlice';
import rolesSlice from '../pages/dashboardPage/components/organizations/parentOrgDetails/addCreateUserModal/rolesSlice';
import userSlice from '../pages/dashboardPage/components/organizations/parentOrgDetails/addCreateUserModal/userSlice';
import trialsSlice from '../pages/dashboardPage/components/organizations/parentOrgDetails/orgCustomers/trialsSlice';
import oktaSlice from '../pages/oktaPage/oktaSlice';
import parentOrgMigrationSlice from '../pages/dashboardPage/components/parentOrgsMigration/parentOrgMigrationSlice';
import parentAuditLogSlice from '../pages/dashboardPage/components/organizations/parentOrgDetails/auditLog/parentAuditLogSlice';
import { auditLogApi } from '../services/auditLogApi';

const rootReducer = combineReducers({
    login: loginPageSlice,
    onboard: onboardSlice,
    organization: createOrgSlice,
    organizations: organizationsSlice,
    roles: rolesSlice,
    users: userSlice,
    trials: trialsSlice,
    okta: oktaSlice,
    auditLog: auditLogSlice,
    parentAuditLogSlice,
    messages: messagesSlice,
    alert: alertSlice,
    userRoles: userRolesSlice,
    features: featuresSlice,
    organizationDefaultFeatures: organizationDefaultFeaturesSlice,
    organizationFeatures: organizationFeaturesSlice,
    permissions: permissionsSlice,
    migration: parentOrgMigrationSlice,
    [auditLogApi.reducerPath]: auditLogApi.reducer,
});

export default rootReducer;
