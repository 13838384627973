/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../../../../../common/constants';
import apiV1 from '../../../../../../services/axiosConfig/apiV1';

const initialState = {
    getRolesStatus: FETCH_STATUS.INIT,
    rolesResult: {},
    updateUserRoleStatus: FETCH_STATUS.INIT,
    updateUserRoleResult: {},
};

export const getRoles = createAsyncThunk(
    'roles/getRoles',
    async ({ bfg }, { rejectWithValue }) => {
        try {
            let url = `/bfg/roles`;

            if (bfg) {
                url += '?isBFGRoles=1';
            }

            return await apiV1.sendRequest(url);
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const updateUserRole = createAsyncThunk(
    'roles/updateUserRole',
    async ({ userId, body }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `bfg/users/${userId}/updateRole`,
                'put',
                body,
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

const rolesSlice = createSlice({
    name: 'rolesSlice',
    initialState,
    reducers: {},
    extraReducers: {
        [getRoles.pending]: (state) => {
            state.getRolesStatus = FETCH_STATUS.FETCHING;
        },
        [getRoles.fulfilled]: (state, action) => {
            state.getRolesStatus = FETCH_STATUS.SUCCESS;
            state.rolesResult = action?.payload;
        },
        [getRoles.rejected]: (state, action) => {
            state.getRolesStatus = FETCH_STATUS.ERROR;
            state.rolesResult = action?.payload;
        },
        [updateUserRole.pending]: (state) => {
            state.updateUserRoleStatus = FETCH_STATUS.FETCHING;
        },
        [updateUserRole.fulfilled]: (state, action) => {
            state.updateUserRoleStatus = FETCH_STATUS.SUCCESS;
            state.updateUserRoleResult = action?.payload;
        },
        [updateUserRole.rejected]: (state, action) => {
            state.updateUserRoleStatus = FETCH_STATUS.ERROR;
            state.updateUserRoleResult = action?.payload;
        },
    },
});

export default rolesSlice.reducer;
