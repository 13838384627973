import React from 'react';
import { useSelector } from 'react-redux';

import s from './navbar.module.scss';

const NavbarDashboard = () => {
    const { userInfo } = useSelector((state) => state?.login);

    return (
        <nav className={s.navContainer}>
            <h2 className={s.navLogo} data-testid="navHeading">
                STAC
            </h2>

            <div className={s.navigationActionsContainer}>
                <h3 className={s.navEmail} data-testid="navEmail">
                    {userInfo.email}
                </h3>
            </div>
        </nav>
    );
};

export default NavbarDashboard;
