import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const DatePicker = ({ name, label, ...rest }) => {
    const {
        formState: { errors },
        control,
    } = useFormContext();

    const error = errors[name];

    const helperText = error ? error.message || error.type : '';

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (
                        <DesktopDatePicker
                            {...field}
                            label={label}
                            renderInput={(inputProps) => (
                                <TextField
                                    {...inputProps}
                                    error={!!error}
                                    helperText={helperText}
                                    size="small"
                                    fullWidth
                                    {...rest}
                                />
                            )}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );
};

export default DatePicker;
