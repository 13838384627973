import * as yup from 'yup';

import { REQUIRED_MESSAGE } from '../../../../../../common/constants';

export const defaultValues = {
    name: '',
};

export const schema = yup
    .object({
        name: yup.string().required(REQUIRED_MESSAGE),
    })
    .required();

export const boxStyles = {
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'end',
};
