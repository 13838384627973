/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../../../common/constants';
import apiV1 from '../../../../services/axiosConfig/apiV1';

const initialState = {
    onboardStatus: FETCH_STATUS.INIT,
    onboardInternalUserStatus: FETCH_STATUS.INIT,
    onboardResult: {},
    isModalShown: false,

    // accounts state
    getAccountsStatus: FETCH_STATUS.INIT,
    getAccountsResult: {},
    offset: 0,
    limit: 25,

    // account details
    getAccountDetailsStatus: FETCH_STATUS.INIT,
    getAccountDetailsResult: {},

    // account orgs state
    getAccountOrgsStatus: FETCH_STATUS.INIT,
    getAccountOrgsResult: {},

    // delete account state
    deleteAccountStatus: FETCH_STATUS.INIT,
    deleteAccountResult: {},

    // change role / delete User
    changeUserRoleStatus: FETCH_STATUS.INIT,
    changeUserRoleResult: {},

    // users invites
    getUsersInvitesStatus: FETCH_STATUS.INIT,
    getUsersInvitesResult: {},

    // users CSV
    getUsersCSVStatus: FETCH_STATUS.INIT,
    getUsersCSVResult: {},

    // user dynamic role
    changeDynamicRoleStatus: FETCH_STATUS.INIT,
    changeDynamicRoleResult: {},

    // user manager
    changeUserManagerStatus: FETCH_STATUS.INIT,
    changeUserManagerResult: {},

    // dynamic user edit
    editDynamicUserStatus: FETCH_STATUS.INIT,
    editDynamicUserResult: {},
};

export const deleteAccount = createAsyncThunk(
    'onboard/deleteAccount',
    async ({ id }, { rejectWithValue }) => {
        try {
            const url = `/bfg/users/${id}`;

            return await apiV1.sendRequest(url, 'delete');
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getAccountOrgs = createAsyncThunk(
    'onboard/getAccountOrgs',
    async ({ id, orgName = '' }, { rejectWithValue }) => {
        try {
            let url = `/bfg/users/${id}/orgs`;

            if (orgName.length) {
                url += `?orgName=${encodeURIComponent(orgName)}`;
            }

            return await apiV1.sendRequest(url, 'get');
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getAccountDetails = createAsyncThunk(
    'onboard/getAccountDetails',
    async ({ id }, { rejectWithValue }) => {
        try {
            const url = `/bfg/users/${id}`;

            return await apiV1.sendRequest(url, 'get');
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getAccounts = createAsyncThunk(
    'onboard/getAccounts',
    async ({ emailValue, limit, offset }, { rejectWithValue }) => {
        try {
            let url = `/bfg/users?offset=${offset}&limit=${limit}`;

            if (emailValue !== undefined) {
                url = `/bfg/users?email=${encodeURIComponent(
                    emailValue,
                )}&offset=${offset}&limit=${limit}`;
            }
            return await apiV1.sendRequest(url, 'get');
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const onboardInternalCiscoUser = createAsyncThunk(
    'onboard/onboardInternalCiscoUser',
    async (onboardData, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                '/bfg/onboarding/internal',
                'post',
                onboardData,
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const onboardUser = createAsyncThunk(
    'onboard/onboardUser',
    async (onboardData, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                '/bfg/onboarding',
                'post',
                onboardData,
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const changeUserRole = createAsyncThunk(
    'onboard/changeUserRole',
    async ({ payload, userId, isRemoveUser }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/users/stac/${userId}/role`,
                isRemoveUser ? 'delete' : 'put',
                payload,
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const changeUserManager = createAsyncThunk(
    'onboard/changeUserManager',
    async ({ payload, userId }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/users/${userId}/manager`,
                'put',
                payload,
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const updateDynamicUser = createAsyncThunk(
    'onboard/updateDynamicUser',
    async ({ payload, userId }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/users/${userId}/updateDynamicUser`,
                'put',
                payload,
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getUsersInvites = createAsyncThunk(
    'onboard/getUsersInvites',
    async ({ userId }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/users/${userId}/orgs/invites`,
                'get',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getUsersCSV = createAsyncThunk(
    'onboard/getUsersCSV',
    async ({ entities }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/users/csv?entity=${entities}`,
                'get',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

const onboardingSlice = createSlice({
    name: 'onboardSlice',
    initialState,
    reducers: {
        clearOnboardResult(state) {
            return {
                ...state,
                onboardResult: {},
            };
        },
        toggleModal(state) {
            return {
                ...state,
                isModalShown: !state.isModalShown,
            };
        },
        refreshGetAccountsState(state) {
            return {
                ...state,
                getAccountsStatus: FETCH_STATUS.INIT,
                getAccountsResult: {},
            };
        },
        setPageSize(state, action) {
            return {
                ...state,
                limit: action.payload,
            };
        },
        setOffset(state, action) {
            return {
                ...state,
                offset: action.payload,
            };
        },
        clearOffsetAndLimit(state) {
            return {
                ...state,
                limit: 10,
                offset: 0,
                getAccountsStatus: FETCH_STATUS.INIT,
                getAccountsResult: {},
            };
        },
        clearAccountDetailsAndOrgs(state) {
            return {
                ...state,
                getAccountDetailsStatus: FETCH_STATUS.INIT,
                getAccountDetailsResult: {},
                getAccountOrgsStatus: FETCH_STATUS.INIT,
                getAccountOrgsResult: {},
            };
        },
        resetOnboardStatus(state) {
            return {
                ...state,
                onboardStatus: FETCH_STATUS.INIT,
            };
        },
        resetUserRoleStatus(state) {
            return {
                ...state,
                changeUserRoleStatus: FETCH_STATUS.INIT,
            };
        },
        resetUserCSVStatus(state) {
            return {
                ...state,
                getUsersCSVStatus: FETCH_STATUS.INIT,
            };
        },
        resetDeleteAccountStatus(state) {
            return {
                ...state,
                deleteAccountStatus: FETCH_STATUS.INIT,
            };
        },

        resetChangeUserManager(state) {
            return {
                ...state,
                changeUserManagerStatus: FETCH_STATUS.INIT,
            };
        },
        resetDynamicUser(state) {
            return {
                ...state,
                editDynamicUserStatus: FETCH_STATUS.INIT,
            };
        },
        resetGetAccountsStatus(state) {
            return {
                ...state,
                getAccountsStatus: FETCH_STATUS.INIT,
            };
        },
    },
    extraReducers: {
        // onboard section
        [onboardUser.pending]: (state) => {
            state.onboardStatus = FETCH_STATUS.FETCHING;
        },
        [onboardUser.fulfilled]: (state, action) => {
            state.onboardStatus = FETCH_STATUS.SUCCESS;
            state.isModalShown = !state.isModalShown;
            state.onboardResult = action?.payload;
        },
        [onboardUser.rejected]: (state, action) => {
            state.onboardStatus = FETCH_STATUS.ERROR;
            state.onboardResult = action?.payload;
        },

        // internal cisco user onboarding
        [onboardInternalCiscoUser.pending]: (state) => {
            state.onboardInternalUserStatus = FETCH_STATUS.FETCHING;
        },
        [onboardInternalCiscoUser.fulfilled]: (state, action) => {
            state.onboardInternalUserStatus = FETCH_STATUS.SUCCESS;
            state.onboardResult = action?.payload;
        },
        [onboardInternalCiscoUser.rejected]: (state, action) => {
            state.onboardInternalUserStatus = FETCH_STATUS.ERROR;
            state.onboardResult = action?.payload;
        },

        // accounts section
        [getAccounts.pending]: (state) => {
            state.getAccountsStatus = FETCH_STATUS.FETCHING;
        },
        [getAccounts.fulfilled]: (state, action) => {
            state.getAccountsStatus = FETCH_STATUS.SUCCESS;
            state.getAccountsResult = action?.payload;
        },
        [getAccounts.rejected]: (state, action) => {
            state.getAccountsStatus = FETCH_STATUS.ERROR;
            state.getAccountsResult = action?.payload;
        },

        // get account by id
        [getAccountDetails.pending]: (state) => {
            state.getAccountDetailsStatus = FETCH_STATUS.FETCHING;
        },
        [getAccountDetails.fulfilled]: (state, action) => {
            state.getAccountDetailsStatus = FETCH_STATUS.SUCCESS;
            state.getAccountDetailsResult = action?.payload;
        },
        [getAccountDetails.rejected]: (state, action) => {
            state.getAccountDetailsStatus = FETCH_STATUS.ERROR;
            state.getAccountDetailsResult = action?.payload;
        },

        // get account orgs by account id
        [getAccountOrgs.pending]: (state) => {
            state.getAccountOrgsStatus = FETCH_STATUS.FETCHING;
        },
        [getAccountOrgs.fulfilled]: (state, action) => {
            state.getAccountOrgsStatus = FETCH_STATUS.SUCCESS;
            state.getAccountOrgsResult = action?.payload;
        },
        [getAccountOrgs.rejected]: (state, action) => {
            state.getAccountOrgsStatus = FETCH_STATUS.ERROR;
            state.getAccountOrgsResult = action?.payload;
        },

        // delete account
        [deleteAccount.pending]: (state) => {
            state.deleteAccountStatus = FETCH_STATUS.FETCHING;
        },
        [deleteAccount.fulfilled]: (state, action) => {
            state.deleteAccountStatus = FETCH_STATUS.SUCCESS;
            state.deleteAccountResult = action?.payload;
        },
        [deleteAccount.rejected]: (state, action) => {
            state.deleteAccountStatus = FETCH_STATUS.ERROR;
            state.deleteAccountResult = action?.payload;
        },

        // change role
        [changeUserRole.pending]: (state) => {
            state.changeUserRoleStatus = FETCH_STATUS.FETCHING;
        },
        [changeUserRole.fulfilled]: (state, action) => {
            state.changeUserRoleStatus = FETCH_STATUS.SUCCESS;
            state.changeUserRoleResult = action?.payload;
        },
        [changeUserRole.rejected]: (state, action) => {
            state.changeUserRoleStatus = FETCH_STATUS.ERROR;
            state.changeUserRoleResult = action?.payload;
        },

        // users invites
        [getUsersInvites.pending]: (state) => {
            state.getUsersInvitesStatus = FETCH_STATUS.FETCHING;
        },
        [getUsersInvites.fulfilled]: (state, action) => {
            state.getUsersInvitesStatus = FETCH_STATUS.SUCCESS;
            state.getUsersInvitesResult = action?.payload;
        },
        [getUsersInvites.rejected]: (state, action) => {
            state.getUsersInvitesStatus = FETCH_STATUS.ERROR;
            state.getUsersInvitesResult = action?.payload;
        },

        // users CSV
        [getUsersCSV.pending]: (state) => {
            state.getUsersCSVStatus = FETCH_STATUS.FETCHING;
        },
        [getUsersCSV.fulfilled]: (state, action) => {
            state.getUsersCSVStatus = FETCH_STATUS.SUCCESS;
            state.getUsersCSVResult = action?.payload;
        },
        [getUsersCSV.rejected]: (state, action) => {
            state.getUsersCSVStatus = FETCH_STATUS.ERROR;
            state.getUsersCSVResult = action?.payload;
        },

        // changeUserManager
        [changeUserManager.pending]: (state) => {
            state.changeUserManagerStatus = FETCH_STATUS.FETCHING;
        },
        [changeUserManager.fulfilled]: (state, action) => {
            state.changeUserManagerStatus = FETCH_STATUS.SUCCESS;
            state.changeUserManagerResult = action?.payload;
        },
        [changeUserManager.rejected]: (state, action) => {
            state.changeUserManagerStatus = FETCH_STATUS.ERROR;
            state.changeUserManagerResult = action?.payload;
        },

        // updateDynamicUser
        [updateDynamicUser.pending]: (state) => {
            state.editDynamicUserStatus = FETCH_STATUS.FETCHING;
        },
        [updateDynamicUser.fulfilled]: (state, action) => {
            state.editDynamicUserStatus = FETCH_STATUS.SUCCESS;
            state.editDynamicUserResult = action?.payload;
        },
        [updateDynamicUser.rejected]: (state, action) => {
            state.editDynamicUserStatus = FETCH_STATUS.ERROR;
            state.editDynamicUserResult = action?.payload;
        },
    },
});

export const {
    clearOnboardResult,
    toggleModal,
    refreshGetAccountsState,
    setPageSize,
    setOffset,
    clearOffsetAndLimit,
    clearAccountDetailsAndOrgs,
    resetOnboardStatus,
    resetUserRoleStatus,
    resetUserCSVStatus,
    resetDeleteAccountStatus,
    resetGetAccountsStatus,
    resetChangeUserManager,
    resetDynamicUser,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
