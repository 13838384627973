class InactivityTimer {
    constructor({ timeout, onTimeout }) {
        this.timer = this;
        this.onTimeout = onTimeout;
        this.timeout = timeout;

        this.eventHandler = this.resetTimer.bind(this);
        this.storageCheck = this.checkCrossTabActivity.bind(this);

        this.startTimer();
        this.tracker();

        setInterval(() => {
            this.storageCheck();
        }, 60 * 1000);
    }

    startTimer() {
        this.timer = window.setTimeout(
            () => this.onTimeout(),
            this.timeout * 60 * 1000,
        );
    }

    checkCrossTabActivity() {
        if (localStorage.getItem('isActive')) {
            this.cleanUp();
            this.startTimer();
            this.tracker();
        }
        localStorage.removeItem('isActive');
    }

    resetTimer() {
        localStorage.setItem('isActive', true);
        this.cleanUp();
        this.startTimer();
        this.tracker();
    }

    tracker() {
        window.addEventListener('mousemove', this.eventHandler);
        window.addEventListener('mousedown', this.eventHandler);
        window.addEventListener('click', this.eventHandler);
        window.addEventListener('scroll', this.eventHandler);
        window.addEventListener('keydown', this.eventHandler);
    }

    cleanUp() {
        window.clearTimeout(this.timer);
        window.removeEventListener('mousemove', this.eventHandler);
        window.removeEventListener('mousedown', this.eventHandler);
        window.removeEventListener('click', this.eventHandler);
        window.removeEventListener('scroll', this.eventHandler);
        window.removeEventListener('keydown', this.eventHandler);
    }
}
export default InactivityTimer;
