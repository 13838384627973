/* eslint-disable no-param-reassign */
import {
    bindActionCreators,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { updateAlertParams } from '../alert/alertSlice';
import { FETCH_STATUS } from '../../common/constants';
import apiV1 from '../../services/axiosConfig/apiV1';
import { errorHandler } from '../../common/utils';

const BASE_PREFIX = '/bfg/organizationDefaultFeatures';

const initialState = {
    getOrganizationDefaultFeaturesStatus: FETCH_STATUS.INIT,
    getOrganizationDefaultFeaturesResult: {},
    // create organization default feature
    createOrganizationDefaultFeatureStatus: FETCH_STATUS.INIT,
    createdOrganizationDefaultFeatureResult: {},
    // update organization default feature
    updateOrganizationDefaultFeatureStatus: FETCH_STATUS.INIT,
    updatedOrganizationDefaultFeatureResult: {},
    // delete organization default feature
    deleteOrganizationDefaultFeatureStatus: FETCH_STATUS.INIT,
    deleteOrganizationDefaultFeatureResult: {},
};

export const createOrganizationDefaultFeature = createAsyncThunk(
    'createOrganizationDefaultFeature',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                BASE_PREFIX,
                'post',
                payload,
            );
            const message = 'OrganizationDefaultFeature created successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const updateOrganizationDefaultFeature = createAsyncThunk(
    'updateOrganizationDefaultFeature',
    async ({ featureId, value }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${featureId}`,
                'put',
                { value },
            );
            const message = 'OrganizationDefaultFeature updated successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const deleteOrganizationDefaultFeature = createAsyncThunk(
    'deleteOrganizationDefaultFeature',
    async (featureId, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiV1.sendRequest(
                `${BASE_PREFIX}/${featureId}`,
                'delete',
            );
            const message = 'OrganizationDefaultFeature deleted successfully';
            dispatch(updateAlertParams({ message, severity: 'success' }));
            return response;
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const getAllOrganizationDefaultFeatures = createAsyncThunk(
    'getOrganizationDefaultFeatures',
    async (_, { rejectWithValue, dispatch }) => {
        const url = `${BASE_PREFIX}`;
        try {
            const response = await apiV1.sendRequest(url, 'get');
            return response.data;
        } catch (err) {
            const message = err.error;
            dispatch(updateAlertParams({ message, severity: 'error' }));
            return rejectWithValue(err);
        }
    },
);

const organizationDefaultFeaturesSlice = createSlice({
    name: 'OrganizationDefaultFeaturesSlice',
    initialState,
    reducers: {},
    extraReducers: {
        // create OrganizationDefaultFeature
        [createOrganizationDefaultFeature.pending]: (state) => {
            state.createOrganizationDefaultFeatureStatus =
                FETCH_STATUS.FETCHING;
        },
        [createOrganizationDefaultFeature.fulfilled]: (state, action) => {
            state.createOrganizationDefaultFeatureStatus = FETCH_STATUS.SUCCESS;
            state.createdOrganizationDefaultFeatureResult = action.payload;
        },
        [createOrganizationDefaultFeature.rejected]: (state) => {
            state.createOrganizationDefaultFeatureStatus = FETCH_STATUS.ERROR;
        },
        // update OrganizationDefaultFeature
        [updateOrganizationDefaultFeature.pending]: (state) => {
            state.updatedOrganizationDefaultFeatureStatus =
                FETCH_STATUS.FETCHING;
        },
        [updateOrganizationDefaultFeature.fulfilled]: (state, action) => {
            state.updatedOrganizationDefaultFeatureStatus =
                FETCH_STATUS.SUCCESS;
            state.updatedOrganizationDefaultFeatureResult = action.payload;
            const updatedItem = action.payload.data.data;
            const updatedFeatureId = updatedItem.id;
            const record = state.getOrganizationDefaultFeaturesResult.data.find(
                (feature) => feature.id === updatedFeatureId,
            );

            Object.assign(record, updatedItem);
        },
        [updateOrganizationDefaultFeature.rejected]: (state) => {
            state.updatedOrganizationDefaultFeatureStatus = FETCH_STATUS.ERROR;
        },
        // deleteFeature
        [deleteOrganizationDefaultFeature.pending]: (state) => {
            state.deleteOrganizationDefaultFeatureStatus =
                FETCH_STATUS.FETCHING;
        },
        [deleteOrganizationDefaultFeature.fulfilled]: (state) => {
            state.deleteOrganizationDefaultFeatureStatus = FETCH_STATUS.SUCCESS;
        },
        [deleteOrganizationDefaultFeature.rejected]: (state) => {
            state.deleteOrganizationDefaultFeatureStatus = FETCH_STATUS.ERROR;
        },
        // getAllOrganizationDefaultFeatures
        [getAllOrganizationDefaultFeatures.pending]: (state) => {
            state.getOrganizationDefaultFeaturesStatus = FETCH_STATUS.FETCHING;
        },
        [getAllOrganizationDefaultFeatures.fulfilled]: (state, action) => {
            state.getOrganizationDefaultFeaturesStatus = FETCH_STATUS.SUCCESS;
            state.getOrganizationDefaultFeaturesResult = action?.payload;
        },
        [getAllOrganizationDefaultFeatures.rejected]: (state) => {
            state.getOrganizationDefaultFeaturesStatus = FETCH_STATUS.ERROR;
        },
    },
});

export const organizationDefaultFeaturesSliceActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(
        {
            createOrganizationDefaultFeature,
            updateOrganizationDefaultFeature,
            deleteOrganizationDefaultFeature,
            getAllOrganizationDefaultFeatures,
        },
        dispatch,
    );
};

export default organizationDefaultFeaturesSlice.reducer;
